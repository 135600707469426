import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../elements/typography'
import TextCta from '../../compositions/textCta'
import WidthContainer from '../../elements/widthContainer'
import { innerHtml } from '../../utils/innerHtml'

const Wrapper = styled.div`
  .textContainer {
    max-width: 75%;
    padding-bottom: 46px;

    @media screen and (max-width: 740px) {
      max-width: 100%;
    }
  }
`

const OverviewIntro = ({ title, text, cta }) => {
  return (
    <WidthContainer variant="full">
      <Wrapper>
        <div className="textContainer">
          <TypographyElement variant="title">{title}</TypographyElement>
          <TypographyElement variant="p">{innerHtml(text)}</TypographyElement>
        </div>
        <TextCta {...cta} />
      </Wrapper>
    </WidthContainer>
  )
}

export default OverviewIntro
