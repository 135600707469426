import React from 'react'
import styled from 'styled-components'

import LocationCard from '../../compositions/locationCard'
import WidthContainer from '../../elements/widthContainer'
import PaginationElement from '../../elements/pagination'
import TypographyElement from '../../elements/typography'
import { grey_background } from '../../colors'

const LocationsWrapper = styled.div`
  display: block;
  padding: 64px 198px;
  background-color: ${grey_background};

  @media screen and (max-width: 1680px) {
    padding: 64px 100px;
  }

  @media screen and (max-width: 1380px) {
    padding: 64px 50px;
  }

  @media screen and (max-width: 1000px) {
    padding: 34px 20px;
  }

  .description {
    padding-bottom: 18px;
  }

  .locations {
    display: block;
    margin-bottom: 27px;
    > * {
      margin-bottom: 57px;
      display: block;
    }
  }
`

const Locations = ({ title, text, locations, perPage = 6, totalCount }) => {
  const [page, setPage] = React.useState(1)
  const [list, setList] = React.useState(locations?.slice(0, perPage || []))

  const handlePaginationChange = (event, value) => {
    const start = (value - 1) * perPage
    const end = value * perPage
    const newLocations = locations.slice(start, end)
    setList(newLocations)
    setPage(value)
  }

  const pages = perPage ? Math.ceil(totalCount / perPage) : 0

  return (
    <WidthContainer variant="full">
      <LocationsWrapper>
        <TypographyElement component="h2" variant="locationsTitle">
          {title}
        </TypographyElement>
        <div className="description">
          <TypographyElement component="p" variant="p">
            {text}
          </TypographyElement>
        </div>

        <div className="locations">
          {list?.map((location, index) => {
            return (
              <LocationCard
                {...location}
                key={`location-${index}-${location.title}`}
                isSmall={true}
              />
            )
          })}
        </div>
        {pages > 1 && (
          <PaginationElement
            count={pages}
            handleChange={handlePaginationChange}
          />
        )}
      </LocationsWrapper>
    </WidthContainer>
  )
}

export default Locations
