import React from 'react'
import styled from 'styled-components'
import { search_border_grey, secondary_green } from '../../colors'
import ButtonElement from '../../elements/buttons'

import TypographyElement from '../../elements/typography'

const Container = styled.div`
  padding: 24px 26px;
  border: 1px solid ${search_border_grey};
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: middle;
    margin-bottom: 7px;
    .info {
      > * {
        display: inline-block;
        vertical-align: middle;
      }
      .category {
        padding: 1px 13px;
        background: ${secondary_green};
        margin-left: 16px;
      }
    }
  }
  .description {
    display: block;
    > div {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0;
      margin: 0;
    }
  }
`

const SearchSiteCard = ({
  title,
  description,
  date,
  category,
  pagetype,
  sports,
  url,
  slug,
}) => {
  const link = pagetype == 'Aanbieder' ? `/sportaanbieders/${slug}` : url
  return (
    <Container>
      <ButtonElement variant="base" href={link}>
        <div className="title-container">
          <TypographyElement variant="searchSiteTitle">
            {title}
          </TypographyElement>
          <div className="info">
            {date ? (
              <TypographyElement variant="searchSiteDate">
                {date}
              </TypographyElement>
            ) : null}
            {category || pagetype ? (
              <div className="category">
                <TypographyElement variant="searchSiteCategory">
                  {category || pagetype}
                </TypographyElement>
              </div>
            ) : null}
          </div>
        </div>
        <div className="description">
          <TypographyElement variant="p">{description}</TypographyElement>
        </div>
      </ButtonElement>
    </Container>
  )
}

export default SearchSiteCard
