import React from 'react'
import styled from 'styled-components'

import ImageBuilder from '../../elements/imageBuilder'

const Wrapper = styled.div`
  width: 100%;
  aspect-ratio: 2.913;
  margin-bottom: -20px;
  span {
    width: 100% !important;
    aspect-ratio: 2.913;
    img {
      width: 100%;
      aspect-ratio: 2.913;
      object-fit: cover;
      object-position: center;
    }
  }
`

const MapLocation = ({ src }) => {
  return (
    <Wrapper>
      <ImageBuilder image={src} width={1920} height={659} />
    </Wrapper>
  )
}

export default MapLocation
