import React from 'react'
import styled from 'styled-components'

import SearchCard from '../../compositions/searchCard'
import WidthContainer from '../../elements/widthContainer'
import PaginationElement from '../../elements/pagination'
import TypographyElement from '../../elements/typography'
import { grey_background } from '../../colors'

const ProvidersWrapper = styled.div`
  display: block;
  padding: 64px 198px;

  @media screen and (max-width: 1380px) {
    padding: 64px 100px;
  }

  @media screen and (max-width: 1180px) {
    padding: 64px 50px;
  }

  @media screen and (max-width: 1000px) {
    padding: 34px 20px;
  }

  background-color: ${(props) => props.background};
  .description {
    padding-bottom: 18px;
  }

  .providers {
    display: block;
    margin-bottom: 27px;
    > * {
      margin-bottom: 57px;
      display: block;
    }
  }
`

const Providers = ({
  title,
  text,
  providers,
  perPage = 6,
  totalCount,
  background = grey_background,
}) => {
  const [page, setPage] = React.useState(1)
  const [list, setList] = React.useState(providers?.slice(0, perPage || []))

  const handlePaginationChange = (event, value) => {
    const start = (value - 1) * perPage
    const end = value * perPage
    const newProviders = providers.slice(start, end)
    setList(newProviders)
    setPage(value)
  }

  const pages = perPage ? Math.ceil(totalCount / perPage) : 0

  return (
    <WidthContainer variant="full">
      <ProvidersWrapper background={background}>
        <TypographyElement component="h2" variant="locationsTitle">
          {title}
        </TypographyElement>
        <div className="description">
          <TypographyElement component="p" variant="p">
            {text}
          </TypographyElement>
        </div>
        {list?.length ? (
          <div className="providers">
            {list?.map((provider, index) => {
              if (!provider.title) {
                return null
              }
              return (
                <SearchCard
                  {...provider}
                  key={`provider-${index}-${provider.title}`}
                  isSmall={true}
                />
              )
            })}
          </div>
        ) : null}
        {pages > 1 && (
          <PaginationElement
            count={pages}
            handleChange={handlePaginationChange}
          />
        )}
      </ProvidersWrapper>
    </WidthContainer>
  )
}

export default Providers
