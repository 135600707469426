import React from 'react'
import Iframe from '../../elements/iframe'
import WidthContainer from '../../elements/widthContainer'

const IframeSection = ({ title, src, aspect_ratio, width }) => {
  if (!src) {
    return null
  }
  return (
    <WidthContainer variant="semi">
      <Iframe
        src={src}
        title={title}
        aspectRatio={aspect_ratio}
        width={width}
      />
    </WidthContainer>
  )
}

export default IframeSection
