import AddEvent from './addEvent'
import Article from './article'
import ColoredLinksSections from './coloredLinks'
import CtaSection from './ctaSection'
import FaqSection from './faq'
import FilterContentSection from './filterContentList'
import FooterSection from './footer'
import HeaderSection from './header'
import HtmlSection from './html'
import IframeSection from './iframeSection'
import Information from './information'
import LinkListImageSection from './linkListImageSection'
import LocationInfo from './locationInfo'
import LocationIntro from './locationIntro'
import Locations from './locations'
import LocationSquareSection from './locationsSquareSection'
import MapLocation from './maplocation'
import MediaTextSection from './mediaText'
import NewsHomeSection from './newsHomeSection'
import NewsSection from './newsSection'
import OpeningSchema from './openingSchema'
import OverviewIntro from './overviewIntro'
import ProductChoiceSection from './productChoice'
import Providers from './providers'
import SearchSection from './search'
import SuccessSection from './successSection'
import TextBlock from './textblock'
import UpcomingEvents from './upcomingEvents'
import UpcomingEventsSmall from './upcomingEventsSmall'
import VimeoSection from './vimeo'
import HomeCtaSection from './homeCta'
import EventCalendarHome from './eventsCalendarHome'
import SkewedCta from './skewedCta'
import BodyArticle from './bodyArticle'
import BodyListColumns from './bodyListColumns'
import PlayingFieldsSection from './playingFields'
import StaticMapSection from './mapsStatic'
import BodyTable from './bodyTable'
import FormBuilderSection from './FormBuilderSection'
import TableSection from './table'

// Subsite sections
import AboutSection from '../subsiteSections/about'
import ActivitiesSection from '../subsiteSections/activities'
import AllFacilities from '../subsiteSections/allFacilities'
import SubsiteArticle from '../subsiteSections/article'
import ArticleIntro from '../subsiteSections/articleIntro'
import ArticleRichText from '../subsiteSections/articleRichText'
import ContactSection from '../subsiteSections/contactSection'
import FacilitiesSection from '../subsiteSections/facilities'
import SubsiteFooterSection from '../subsiteSections/footer'
import SubsiteHeaderSection from '../subsiteSections/header'
import MainInfo from '../subsiteSections/mainPageInfo'
import SubsiteNewsSection from '../subsiteSections/newsSection'
import SubsiteShortNewsSection from '../subsiteSections/shortNewsSection'
import Tables from '../subsiteSections/tabels'

const exportsObj = {
  AddEvent,
  Article,
  ColoredLinksSections,
  CtaSection,
  FaqSection,
  FilterContentSection,
  FooterSection,
  HeaderSection,
  HtmlSection,
  IframeSection,
  Information,
  LinkListImageSection,
  LocationInfo,
  LocationIntro,
  Locations,
  LocationSquareSection,
  MapLocation,
  MediaTextSection,
  NewsHomeSection,
  NewsSection,
  OpeningSchema,
  OverviewIntro,
  ProductChoiceSection,
  Providers,
  SearchSection,
  SuccessSection,
  TextBlock,
  UpcomingEvents,
  UpcomingEventsSmall,
  VimeoSection,
  HomeCtaSection,
  EventCalendarHome,
  SkewedCta,
  BodyArticle,
  BodyListColumns,
  PlayingFieldsSection,
  StaticMapSection,
  BodyTable,
  FormBuilderSection,
  TableSection,

  AboutSection,
  ActivitiesSection,
  AllFacilities,
  SubsiteArticle,
  ArticleIntro,
  ArticleRichText,
  ContactSection,
  FacilitiesSection,
  SubsiteFooterSection,
  SubsiteHeaderSection,
  MainInfo,
  SubsiteNewsSection,
  SubsiteShortNewsSection,
  Tables,
}

export default exportsObj
