import React from 'react'
import styled from 'styled-components'
import Vimeo from '@u-wave/react-vimeo'

const VimeoWrapper = styled.div`
  width: 100%;
  margin-bottom: 127px;

  iframe {
    width: 100%;
    height: 100%;
  }
  .vimeoPlayer {
    width: 100%;
  }
`

const VimeoPlayer = ({ video }) => {
  return (
    <VimeoWrapper>
      <Vimeo className="vimeoPlayer" video={video} responsive />
    </VimeoWrapper>
  )
}

export default VimeoPlayer
