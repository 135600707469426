import React from 'react'
import styled from 'styled-components'

import WidthContainer from '../../elements/widthContainer'
import TypographyElement from '../../elements/typography'
import { innerHtml } from '../../utils/innerHtml'
import ButtonElement from '../../elements/buttons'
import GoogleMapModule from '../../modules/mapsPoly'
import { getMomentTz } from '../../utils/getMomentTz'
import { search_border_grey } from '../../colors'

const Wrapper = styled.div`
  margin: 60px 0;
  .tabs {
    margin-top: 16px;
    margin-bottom: 40px;
    button {
      padding: 0;
      margin-right: 13px;
    }
    .tab {
      padding: 7px 23px;
      border: 1px solid ${search_border_grey};
      * {
        margin: 0;
      }
      &.selected {
        background-color: green;
      }
      &.unrated {
        border: 1px solid #000000;
        &.selected {
          background-color: #000000;
          > div {
            color: white !important;
          }
        }
      }
      &.approved {
        border: 1px solid #007b05;
        &.selected {
          background-color: #007b05;
          color: white;
        }
      }
      &.re_approve {
        border: 1px solid #1485cc;
        &.selected {
          background-color: #1485cc;
          color: white;
        }
      }
      &.rejected {
        border: 1px solid #d00000;
        &.selected {
          background-color: #d00000;
          color: white;
        }
      }
      &.in_maintenance {
        border: 1px solid #fffc00;
        &.selected {
          background-color: #fffc00;
          /* color: white; */
        }
      }
    }
    .lastChanged {
      float: right;
    }
  }
`

const Options = [
  {
    label: 'Nog niet gekeurd',
    value: 'unrated',
  },
  {
    label: 'Goedgekeurd',
    value: 'approved',
  },
  {
    label: 'Herkeuren',
    value: 're_approve',
  },
  {
    label: 'Afgekeurd',
    value: 'rejected',
  },
  {
    label: 'Onderhoud',
    value: 'in_maintenance',
  },
]

const PlayingFieldsSection = ({
  title = 'Veldkeuringen',
  text = 'Kijk altijd op je eigen verenigingssite om te kijken welke wedstrijden/trainingen zijn afgelast',
  last_examination,
  fields,
  locationCoordinates,
}) => {
  const [playingfields, setPlayingfields] = React.useState(fields)
  const [selected, setSelected] = React.useState([])

  const handleTab = (item) => {
    if (selected?.includes(item)) {
      const newState = selected.filter((tab) => item != tab)
      const newFields = fields.filter((field) => {
        // check whether field has one of the selected statusses
        return newState.length == 0 || newState.includes(field.status)
      })
      setSelected(newState)
      setPlayingfields(newFields)
    } else {
      const newState = [...selected, item]
      const newFields = fields.filter((field) => {
        // check whether field has one of the selected statusses
        return newState.includes(field.status)
      })
      const newCoords = newFields?.map((playingField) => {
        return playingField?.coordinates
      })
      setSelected(newState)
      setPlayingfields(newFields)
    }
  }

  const lastChanged = getMomentTz(last_examination).format('d MMMM YYYY HH:mm')
  const center =
    locationCoordinates || (playingfields[0] && playingfields[0].coordinates[0])

  return (
    <WidthContainer variant="semi">
      <Wrapper>
        <TypographyElement variant="title">{title}</TypographyElement>
        {text ? <div className="textContainer">{innerHtml(text)}</div> : null}

        <div className="tabs">
          {Options?.map((option) => {
            let isSelected = selected?.includes(option.value)
            return (
              <ButtonElement
                variant="empty"
                handleClick={() => handleTab(option.value)}
                key={`tab-${option.value}`}
              >
                <div
                  className={`tab ${isSelected ? 'selected' : ''} ${
                    option?.value
                  }`}
                >
                  <TypographyElement variant="plusButtonText">
                    {option.label}
                  </TypographyElement>
                </div>
              </ButtonElement>
            )
          })}
          {lastChanged ? (
            <div className="lastChanged">Laatste keuring: {lastChanged}</div>
          ) : null}
        </div>

        {playingfields ? (
          <GoogleMapModule
            fields={playingfields}
            defaultCenter={center}
            defaultZoom={16}
            mapStyling={{
              width: '100%',
              maxHeight: '420px',
            }}
          />
        ) : null}
      </Wrapper>
    </WidthContainer>
  )
}

export default PlayingFieldsSection
