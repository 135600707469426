import React from 'react'
import styled from 'styled-components'
import ButtonElement from '../../elements/buttons'
import { ImageBuilderFrontend } from '../../elements/imageBuilder'

import TypographyElement from '../../elements/typography'

import arrow from './arrow.svg'

const InformationWrapper = styled.div`
  padding: 20px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 17px 98px rgba(0, 0, 0, 0.05),
    0px 3.79717px 21.8896px rgba(0, 0, 0, 0.0298054),
    0px 1.13052px 6.5171px rgba(0, 0, 0, 0.0201946);
  max-width: 400px;

  ${(props) => (props.maxWidth ? 'max-width: 100%;' : '')}

  > div {
    position: relative;
  }

  .title {
    cursor: pointer;
    span {
      float: right;
    }
  }

  .content {
    padding: 12px 0 0 0;
    .description {
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      display: -webkit-box !important;
      -webkit-line-clamp: 7 !important; /* number of lines to show */
      -webkit-box-orient: vertical !important;
    }
  }

  .buttonArea {
    padding-top: 12px;
    a,
    button {
      padding: 0;
      margin: 0;
      text-align: right;
      div {
        > span {
          width: 10px !important;
          height: 8px !important;
          position: relative !important;
          display: inline-block !important;
          margin-left: 18px !important;
        }
      }
    }
  }
`

const InformationCard = ({ title, url, description, preClosed, maxWidth }) => {
  const [open, setOpen] = React.useState(!preClosed)

  return (
    <InformationWrapper maxWidth={maxWidth}>
      <div>
        <TypographyElement variant="breadcrumb" onClick={() => setOpen(!open)}>
          <div className="title">
            {title}

            <span>{open ? '-' : '+'}</span>
          </div>
        </TypographyElement>
        {open && (
          <div className="content">
            <TypographyElement variant="faqText" className="description">
              {description?.substring(0, 266)}
            </TypographyElement>

            <div className="buttonArea">
              <ButtonElement variant="base" href={url}>
                <TypographyElement variant="p">
                  Lees verder
                  <span
                    style={{
                      position: 'relative',
                      width: '20px',
                      height: '20px',
                    }}
                  >
                    <ImageBuilderFrontend image={arrow} alt="arrow" />
                  </span>
                </TypographyElement>
              </ButtonElement>
            </div>
          </div>
        )}
      </div>
    </InformationWrapper>
  )
}
export default InformationCard
