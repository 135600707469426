import React from 'react'
import styled from 'styled-components'

import WidthContainer from '../../elements/widthContainer'
import { grey_background } from '../../colors'
import InfoList from '../../compositions/infoList'

const InfoWrapper = styled.div`
  display: block;
  background-color: ${grey_background};
  margin: 60px 0;

  > div {
    padding-top: 0;
    :first-child {
      padding-top: 84px;
    }
  }
`

const LocationInfo = ({ title, sublocations, facilities }) => {
  const facilitiesMapped = facilities?.map((item) => {
    return item.value || item
  })
  return (
    <WidthContainer variant="full">
      <InfoWrapper>
        {sublocations?.length ? (
          <InfoList
            title={`Sublocaties in ${title}`}
            items={sublocations}
            withArrow={true}
          />
        ) : null}
        {facilities ? (
          <InfoList
            title={`Faciliteiten in ${title}`}
            items={facilitiesMapped}
            withArrow={false}
          />
        ) : null}
      </InfoWrapper>
    </WidthContainer>
  )
}

export default LocationInfo
