import React from 'react'

import WidthContainer from '../../elements/widthContainer'
import FacilityPrices from '../../modules/_subsite/facilityPrices'

const TableSection = (props) => {
  const { sectionData } = props
  const passData = sectionData || props
  return (
    <WidthContainer variant="semi">
      <FacilityPrices {...passData} />
    </WidthContainer>
  )
}

export default TableSection
