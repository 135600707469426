'use client'
import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'

import TypographyElement from '../elements/typography'
import SvgElement from '../elements/svg'
import ColorBar from '../elements/colorbars'

import { white, black } from '../colors'
import { innerHtml } from '../utils/innerHtml'

const Wrapper = styled.div`
  display: inline-block;
  background-color: ${white};
  flex: 0.32;
  position: relative;

  a {
    text-decoration: none;
    color: unset;
    display: block;
  }
  .container {
    padding: 48px 53px 33px;
    .buttonContainer {
      margin-top: 32px;
      margin-right: -20px;
      text-align: right;
      svg {
        path {
          stroke: ${black};
        }
      }
    }

    @media screen and (max-width: 740px) {
      padding: 20px 30px 0px;

      .buttonContainer {
        margin-top: 0;

        svg {
          position: relative;
          top: -32px;
        }
      }
    }
  }
  .barContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
  }

  @media screen and (max-width: 1600px) {
    h2 {
      min-height: 130px;
    }
  }

  @media screen and (max-width: 1050px) {
    h2 {
      min-height: 0px;
    }
  }
`

const ColoredLinkBlock = ({ title, text, href, variant = 'small1' }) => {
  return (
    <Wrapper>
      <Link href={href}>
        <div className="container">
          <TypographyElement variant="greenTitle" component="h2">
            {title}
          </TypographyElement>
          <TypographyElement variant="p" component="span">
            {innerHtml(
              `${text.substring(0, 200)}${text.length > 200 ? '...' : ''}`
            )}
          </TypographyElement>

          <div className="buttonContainer">
            <SvgElement variant="arrowUpperRight" />
          </div>
        </div>
      </Link>
      <div className="barContainer">
        <ColorBar variant={variant} />
      </div>
    </Wrapper>
  )
}

export default ColoredLinkBlock
