import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../elements/typography'
import ProductTable from '../../modules/productTable'
import WidthContainer from '../../elements/widthContainer'

const Wrapper = styled.div``

const ProductChoiceSection = ({ title, text, products }) => {
  return (
    <WidthContainer variant="full">
      <Wrapper>
        <TypographyElement variant="title">{title}</TypographyElement>
        <TypographyElement variant="p">{text}</TypographyElement>
        <ProductTable rows={products} />
      </Wrapper>
    </WidthContainer>
  )
}

export default ProductChoiceSection
