import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../elements/typography'
import TextCta from '../../compositions/textCta'
import WidthContainer from '../../elements/widthContainer'
import { innerHtml } from '../../utils/innerHtml'

const Wrapper = styled.div``

const TextBlock = ({ text }) => {
  return (
    <WidthContainer variant="semi">
      <Wrapper>{innerHtml(text)}</Wrapper>
    </WidthContainer>
  )
}

export default TextBlock
