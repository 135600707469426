'use client'
import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../../elements/typography'
import ButtonElement from '../../../elements/buttons'
import { primary_orange } from '../../../colors'
import { innerHtml } from '../../../utils/innerHtml'

const Wrapper = styled.div`
  .buttonContainer {
    margin-top: 25px;
    display: block;
    text-align: right;
  }
`

const TitleTextLinkBlock = ({ title, text, link }) => {
  return (
    <Wrapper>
      <TypographyElement variant="title" component="h2">
        {title}
      </TypographyElement>
      {innerHtml(text)}
      <div className="buttonContainer">
        {link && (
          <ButtonElement
            variant="baseArrowLink"
            href={link.href || link.external_url}
            color={primary_orange}
          >
            {link.text}
          </ButtonElement>
        )}
      </div>
    </Wrapper>
  )
}

export default TitleTextLinkBlock
