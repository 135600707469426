'use client'
import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material'

import TypographyElement from '../../../elements/typography'
import ActivityCard from '../../../compositions/_subsite/activityCard'
import ButtonElement from '../../../elements/buttons'
import { button_text_black, white } from '../../../colors'

const Wrapper = styled.div`
  padding: 32px 57px;
  background: ${(props) => props.background};
  .itemsContainer {
    display: block;
    > div {
      display: inline-block;
      vertical-align: middle;
      margin-right: 32px;
      height: unset;
      width: calc(33.3% - 21.3px);
      aspect-ratio: 0.86;

      :nth-child(3n + 3) {
        margin-right: 0;
      }

      @media screen and (max-width: 1100px) {
        width: 100%;
        max-width: 100%;
        display: block;
        margin-right: 0;
        margin-bottom: 20px;
        aspect-ratio: initial;
        height: 300px;

        :nth-child(3n + 3) {
          margin-bottom: 0;
        }
      }
    }
  }
  .buttonContainer {
    margin-top: 35px;
    display: block;
    text-align: right;
  }
  @media screen and (max-width: 500px) {
    padding: 32px 20px;
  }
`

const FacilitiesBlock = ({ title, items, link }) => {
  const theme = useTheme()
  const color = theme?.globalTheme?.palette?.primaryCustom?.main

  return (
    <Wrapper background={color}>
      <TypographyElement variant="title" color={white}>
        {title}
      </TypographyElement>
      <div className="itemsContainer">
        {items?.map((item, i) => {
          return <ActivityCard {...item} key={`activity-${i}-${item.text}`} />
        })}
      </div>
      <div className="buttonContainer">
        <ButtonElement
          variant="baseArrowLink"
          href={link.href || link.external_url}
          color={white}
        >
          {link.text}
        </ButtonElement>
      </div>
    </Wrapper>
  )
}

export default FacilitiesBlock
