import React from 'react'
import styled from 'styled-components'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import TypographyElement from '../../elements/typography'
import ImageBuilder from '../../elements/imageBuilder'
import { black, white } from '../../colors'
import Link from 'next/link'

const Card = styled.div`
  height: 410px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: ${(props) => (props.isLarge ? '66%' : '32%')};
  ${(props) => (props.maxWidth ? 'width: 100%;' : '')}
  background: #fff;
  margin-bottom: 30px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  @media screen and (max-width: 860px) {
    width: 100%;
  }

  a {
    text-decoration: none;

    :hover,
    :focus-visible,
    :focus {
      .overlay {
        transform: translateY(-340px)
          rotate(${(props) => (props.isLarge ? '-1deg' : '-2deg')});
      }
    }
  }
  .image {
    height: 304px;
    width: 100%;
    position: relative;

    span {
      height: inherit !important;
      img {
        object-fit: cover;
        object-position: center;
      }
    }
    .gradient {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      width: 100%;
      height: 304px;
      position: absolute;
      z-index: 1;
    }
  }

  div.whitebar {
    position: absolute;
    width: 120%;
    height: 400px;
    background: #fff;
    left: -2%;
    z-index: 1;
    /* bottom: ${(props) => (props.isLarge ? '-45px' : '-60px')}; */
    top: 290px;
    transform: rotate(${(props) => (props.isLarge ? '-1deg' : '-2deg')});
  }

  h3 {
    position: absolute;
    bottom: 10px;
    color: #fff;
    text-shadow: -1px -1px 10px #000;
    overflow: initial;
    z-index: 1;
    white-space: wrap;
    padding: 0 31px 22px;
  }

  .content {
    position: relative;
    top: -20px;
    padding: 21px 31px;
    z-index: 1;
    max-height: 138px;
    .extra {
      text-align: right;
      svg {
        vertical-align: middle;
        display: inline-block;
        margin: 0 9px;
      }
    }
    .title {
      margin-top: 12px;
    }
    > svg {
      position: absolute;
      right: 31px;
      bottom: 21px;
      width: 12px;
      transform: scale(1.5);
      path {
        stroke: 'none';
        fill: ${black};
      }
    }
  }

  .overlay {
    position: relative;
    display: block;
    width: 150%;
    height: 400px;
    left: -25%;
    background: rgba(46, 132, 42, 0.9);
    background-blend-mode: darken;
    transform: translateY(50px) rotate(${(props) => (props.isLarge ? '-1deg' : '-2deg')});
    transition: 0.5s ease-in-out;
    z-index: 1;
    .text {
      position: absolute;
      top: 30%;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      transform: rotate(${(props) => (props.isLarge ? '1deg' : '2deg')});
      svg {
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
        path {
          stroke: none;
          fill: ${white};
        }
      }
    }
  }
  :hover,
  :focus-visible,
  :focus {
    .overlay {
      transform: translateY(-340px)
        rotate(${(props) => (props.isLarge ? '-1deg' : '-2deg')});
    }
  }

  p {
    height: 52px;
    max-height: 52px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .linkoverlay {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 9;
  }

  // Mobile
  @media screen and (max-width: 500px) {
    height: unset;
    .content {
      top: -50px;
      padding: 0 0px 21px;
      max-height: 138px;
      .title {
        h3 {
          white-space: normal;
          color: black;
          top: 0;
          margin-bottom: 12px;
          text-shadow: unset;
        }
      }
      svg {
        display: none;
      }
    }
    .whitebar {
      top: 240px !important;
    }
    .image {
      .gradient {
        display: none;
      }

      img {
        object-fit: cover;
      }
    }
  }
`

const NewsCard = ({
  title,
  href,
  img,
  search_img,
  isLarge = false,
  maxWidth,
  description,
  url,
}) => {
  const link = url || href
  const image = search_img || img
  return (
    <Card isLarge={isLarge} maxWidth={maxWidth}>
      <Link href={link} className="linkoverlay">

          <div className="image">
            <div className="gradient"></div>
            <ImageBuilder image={image} decorativeOnly={true} layout="fill" />

            <TypographyElement variant={'newsCardTitleElipsis'}>
              {title}
            </TypographyElement>
          </div>
          <div className="whitebar"></div>
          <div className="content">
            <div className="title">
              <TypographyElement variant={'faqText'}>
                {description}
              </TypographyElement>
            </div>
            <ArrowForwardIcon />
          </div>
          <div className="overlay">
            <div className="text">
              <TypographyElement variant="readMoreWhite">
                Lees meer
              </TypographyElement>
              <ArrowForwardIcon />
            </div>
          </div>

      </Link>
    </Card>
  )
}

export default NewsCard
