import React from 'react'
import WidthContainer from '../../elements/widthContainer'
import HomeCta from '../../compositions/homeCta'

const HomeCtaSection = ({ link, image }) => {
  return (
    <WidthContainer variant="full">
      <HomeCta link={link} img={image} />
    </WidthContainer>
  )
}

export default HomeCtaSection
