import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material'

import NewsCard from '../../compositions/newsCard'
import WidthContainer from '../../elements/widthContainer'

import TypographyElement from '../../elements/typography'
import ButtonElement from '../../elements/buttons'

const Container = styled.div`
  background: ${(props) => props.background};
  padding: 111px 160px;
  .news {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .button {
    text-align: right;
    > span.linkWrapper {
      width: 100%;
    }
    a {
      text-align: center;
    }

    @media screen and (max-width: 600px) {
      svg {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1700px) {
    padding: 111px 140px;
  }

  @media screen and (max-width: 1600px) {
    padding: 111px 100px;
  }

  @media screen and (max-width: 1300px) {
    padding: 80px 60px;
  }

  @media screen and (max-width: 1100px) {
    padding: 40px 20px;
  }

  @media screen and (max-width: 400px) {
    padding: 40px 0px;
  }
`

const NewsHomeSection = ({
  title,
  featured_title,
  news,
  featured,
  button,
  featured_button,
  first_large,
  hasBackground = true,
}) => {
  const theme = useTheme()

  return (
    <WidthContainer variant="full">
      <Container
        background={
          hasBackground
            ? theme?.globalTheme?.palette?.backgroundColor?.main
            : 'transparent'
        }
      >
        <TypographyElement variant="moreTitle" component="h2">
          {title}
        </TypographyElement>
        <div className="news">
          {news?.map((item, index) => {
            return (
              <NewsCard
                key={`news-card-section-${index}`}
                {...item}
                decorativeOnly={true}
                isLarge={index == 0 && first_large}
              />
            )
          })}
        </div>
        {button?.href && (
          <div className="button">
            <ButtonElement variant="primaryLink" href={button.href}>
              {button.text}
            </ButtonElement>
          </div>
        )}

        {featured && featured.length > 0 && (
          <>
            <TypographyElement variant="moreTitle" component="h2">
              {featured_title}
            </TypographyElement>
            <div className="news">
              {featured?.map((item, index) => {
                return (
                  <NewsCard
                    key={`news-card-section-${index}`}
                    {...item}
                    isLarge={false}
                  />
                )
              })}
            </div>
            {featured_button?.href && (
              <div className="button">
                <ButtonElement
                  variant="primaryLink"
                  href={featured_button.href}
                >
                  {featured_button.text}
                </ButtonElement>
              </div>
            )}
          </>
        )}
      </Container>
    </WidthContainer>
  )
}

export default NewsHomeSection
