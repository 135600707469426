import React from 'react'
import styled from 'styled-components'
import MediaTextOverlapBlock from '../../modules/mediaTextOverlapBlock'
import MediaTextBlock from '../../modules/mediaTextBlock'
import WidthContainer from '../../elements/widthContainer'

const Wrapper = styled.div`
  margin: 132px 0;

  @media screen and (max-width: 1000px) {
    margin: 40px 0;
  }
`

const MediaTextSection = (props) => {
  const Block = props.overlap ? MediaTextOverlapBlock : MediaTextBlock
  return (
    <WidthContainer variant="small">
      <Wrapper>
        <Block {...props} />
      </Wrapper>
    </WidthContainer>
  )
}

export default MediaTextSection
