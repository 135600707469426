import React from 'react'
import VimeoPlayer from '../../elements/vimeo'
import WidthContainer from '../../elements/widthContainer'

const VimeoSection = ({ video }) => {
  if (!video) {
    return null
  }
  return (
    <WidthContainer variant="semi">
      <VimeoPlayer video={video} />
    </WidthContainer>
  )
}

export default VimeoSection
