// HEAVILY WIP
import React from 'react'

import { GoogleMap, useJsApiLoader, Polygon } from '@react-google-maps/api'
import MapsWrapper from './maps.styled'

// Loading indicator
const Spinner = () => {
  return <div> Loading Map </div>
}

// Map settings
const mapContainerStyle = {
  height: '100vh',
  width: '100vw',
}

// Poly options
const options = {
  fillColor: 'lightgrey',
  fillOpacity: 0.5,
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
}

const FieldStateOptions = {
  unrated: {
    fillColor: '#000000',
    strokeColor: '#000000',
  },
  approved: {
    fillColor: '#007B05',
    strokeColor: '#007B05',
  },
  re_approve: {
    fillColor: '#1485CC',
    strokeColor: '#1485CC',
  },
  rejected: {
    fillColor: '#D00000',
    strokeColor: '#D00000',
  },
  in_maintenance: {
    fillColor: '#FFFC00',
    strokeColor: '#FFFC00',
  },
}

// Module
function GoogleMapModule({
  defaultCenter,
  defaultZoom,
  fields,
  mapStyling = {},
}) {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDRzjCwZbOUwluc8pAdAQQV5AlwQv4rliU', // ,
  })

  const styling = {
    ...mapContainerStyle,
    ...mapStyling,
  }

  const renderMap = () => {
    return (
      <MapsWrapper styling={mapStyling}>
        <GoogleMap
          mapContainerStyle={styling}
          defaultZoom={defaultZoom}
          zoom={defaultZoom}
          center={defaultCenter}
        >
          {fields?.map((item, index) => {
            const specificOptions = FieldStateOptions[item.status]
            const polyOptions = { ...options, ...specificOptions }
            return (
              <Polygon
                paths={item.coordinates}
                options={polyOptions}
                key={index}
              />
            )
          })}
        </GoogleMap>
      </MapsWrapper>
    )
  }

  if (loadError) {
    return <div>Map Error</div>
  }

  return isLoaded ? renderMap() : <Spinner />
}

export default GoogleMapModule
