import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../elements/typography'
import Detail from './info'

const Wrapper = styled.div`
  margin-bottom: 50px;
  .items {
    display: block;
  }
  .description {
    padding-top: 24px;
    max-width: 875px;
  }
`

const ClubInfo = ({ title, rows, table }) => {
  // Get the table data from props
  const useRows =
    rows ||
    table?.data
      .filter(
        (item) => item[0] && item[1] // Only rows with a value
      )
      .map((row) => {
        return {
          title: row[0],
          first_column: row[1],
          second_collumn: '',
        } // Fill row with values
      }) ||
    []

  return (
    <Wrapper>
      <TypographyElement component="h2" variant="title">
        {title}
      </TypographyElement>
      <div className="items">
        {useRows?.map((row, i) => {
          return (
            <Detail
              key={`detail-${row.title}-${i}`}
              title={row.title}
              info={row.first_column}
              info2={row.second_collumn}
            />
          )
        })}
      </div>
    </Wrapper>
  )
}

export default ClubInfo
