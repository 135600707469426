import React from 'react'
import styled from 'styled-components'
import ButtonElement from '../../elements/buttons'
import ImageBuilder from '../../elements/imageBuilder'
import TypographyElement from '../../elements/typography'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const Wrapper = styled.div`
  width: 100%;
  max-width: 422px;
  .imageContainer {
    display: block;
    width: 100%;
    span {
      width: 100% !important;
      aspect-ratio: 1.13;
      img {
        object-position: center;
        object-fit: cover;
        width: 100%;
      }
    }

    img {
      object-fit: cover;
    }
  }

  .textContainer {
    display: block;
    padding: 16px 34px;
    > * {
      display: inline-block;
      vertical-align: middle;
      padding: 0;
    }
    span {
      width: calc(100% - 18px);
    }
    > svg {
      width: 18px;
    }
  }
`

const LocationSquareCard = ({ title, href, url, img, search_img }) => {
  const image = search_img || img
  return (
    <Wrapper>
      <ButtonElement variant="base" href={href || url}>
        <div className="imageContainer">
          <ImageBuilder image={image} width={422} height={372} />
        </div>
        <div className="textContainer">
          <TypographyElement variant="cardText" component="span">
            {title}
          </TypographyElement>
          <ArrowForwardIcon width={12} height={11} />
        </div>
      </ButtonElement>
    </Wrapper>
  )
}

export default LocationSquareCard
