import React from 'react'
import styled from 'styled-components'

import { Input } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import ButtonElement from '../../elements/buttons'
import SvgElement from '../../elements/svg'
import { searchbar_bg, tag_border_grey, white } from '../../colors'

const SearchWrapperSmall = styled.div`
  border: 1px solid ${tag_border_grey};
  background: ${white};
  width: 309px;
  border-radius: 27px;
  height: 54px;
  > * {
    display: inline-block;
    vertical-align: middle;
  }
  svg.searchicon {
    margin-left: 30px;
    margin-right: 20px;
    height: 54px;
    cursor: pointer;
  }
  .input {
    width: calc(100% - 70px - 24px - 10px);
  }
  .clear {
    width: 20px;
    height: 20px;
    padding: 4px;
    cursor: pointer;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`

const SearchWrapperLarge = styled.div`
  background: ${searchbar_bg};
  width: 100%;
  border-radius: 26px;
  height: 52px;
  position: relative;
  margin-bottom: 115px;

  > * {
    display: inline-block;
    vertical-align: middle;
  }
  svg.searchicon {
    margin-left: 30px;
    margin-right: 20px;
    height: 52px;
    cursor: pointer;
  }
  .input {
    width: calc(100% - 308px);
  }
  .clear {
    width: 20px;
    height: 20px;
    padding: 4px;
    cursor: pointer;
  }
  .searchButton {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  @media screen and (max-width: 900px) {
    .input {
      width: calc(100% - 200px);
    }
    .searchButton {
      max-width: 150px;
    }
  }
  @media screen and (max-width: 500px) {
    .input {
      width: calc(100% - 102px);
    }
    .searchButton {
      button {
        max-width: 50px;
        span {
          display: none;
        }
      }
    }
  }
`

const wrapperDict = {
  small: SearchWrapperSmall,
  large: SearchWrapperLarge,
}

const Searchbar = ({
  variant = 'small',
  placeholder,
  value,
  setValue,
  handleSubmit,
}) => {
  // const [value, setValue] = React.useState('')
  const SearchWrapper = wrapperDict[variant]

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const clearValue = () => {
    setValue('')
  }

  return (
    <SearchWrapper>
      <SvgElement
        className="searchicon"
        variant="search"
        onClick={handleSubmit}
      />
      <div className="input">
        <Input
          id="search"
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          issearch="true"
          onKeyPress={(e) => {
            if (e.code == 'Enter') {
              handleSubmit()
            }
          }}
        />
      </div>
      {value && (
        <CloseIcon
          className="clear"
          width={9}
          height={9}
          onClick={clearValue}
        />
      )}
      {variant == 'large' && (
        <div className="searchButton">
          <ButtonElement variant="search" handleClick={handleSubmit}>
            Zoeken
          </ButtonElement>
        </div>
      )}
    </SearchWrapper>
  )
}

export default Searchbar
