'use client'
import React from 'react'
import styled from 'styled-components'
import WidthContainer from '../../elements/widthContainer'
import ContactBlock from '../../modules/_subsite/contactBlock'

const Wrapper = styled.div`
  margin-bottom: ${(props) => `${props.bottomSpacing || 0}px`};
`

const ContactSection = ({ text, email, telephone, bottomSpacing = 179 }) => {
  return (
    <WidthContainer variant="semi">
      <Wrapper bottomSpacing={bottomSpacing}>
        <ContactBlock text={text} telephone={telephone} email={email} />
      </Wrapper>
    </WidthContainer>
  )
}

export default ContactSection
