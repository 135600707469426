import React from 'react'
import styled from 'styled-components'
import WidthContainer from '../../elements/widthContainer'

import OpeningSchemaOverview from '../../modules/openingSchemaOverview'
import OpeningSchemaFilter from '../../modules/openingsSchemaFilter'
import CustomMonthDateRangePicker from '../../elements/dateRangePicker'
import { white } from '../../colors'

const Wrapper = styled.div`
  max-width: 987px;
  .calendarContainer {
    box-shadow: 0px 17px 98px rgba(0, 0, 0, 0.05),
      0px 3.79717px 21.8896px rgba(0, 0, 0, 0.0298054),
      0px 1.13052px 6.5171px rgba(0, 0, 0, 0.0201946);
    margin-bottom: 25px;
  }
  .filterContainer {
    background-color: ${white};
    margin-bottom: 25px;
  }
`

const OpeningSchema = ({ filterTitle, filters, results = [] }) => {
  const [selectedDay, setSelectedDay] = React.useState(new Date())
  const [selectedFilter, setSelectedFilter] = React.useState(filters[0].value)

  const [selectionRange, setSelectionRange] = React.useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })

  const handleFilter = (e) => {
    setSelectedFilter(e)
  }

  const handleSelection = (selection) => {
    setSelectedDay(selection.startDate)
  }

  return (
    <WidthContainer variant="full">
      <Wrapper>
        <div className="calendarContainer">
          <CustomMonthDateRangePicker
            doubleCalendar={true}
            focusDate={new Date()}
            minDate={new Date()}
            handleSelection={handleSelection}
            selectionRange={selectionRange}
            setSelectionRange={setSelectionRange}
            singledate={true}
            selectedSingleDate={selectedDay}
          />
        </div>
        <div className="filterContainer">
          <OpeningSchemaFilter
            title={filterTitle}
            filters={filters}
            handleFilter={handleFilter}
            selected={selectedFilter}
          />
        </div>
        <OpeningSchemaOverview selectedDay={selectedDay} results={results} />
      </Wrapper>
    </WidthContainer>
  )
}

export default OpeningSchema
