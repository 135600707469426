import React from 'react'
import styled from 'styled-components'

import ButtonElement from '../../elements/buttons'
import InfoLinkList from '../../compositions/infoLinksList'

import WidthContainer from '../../elements/widthContainer'
import { innerHtml } from '../../utils/innerHtml'
import TypographyElement from '../../elements/typography'
import ClubInfo from '../../compositions/clubInfo'
import TextCta from '../../compositions/textCta'
import { SectionElement } from '../../compositions/articleSection'

const Wrapper = styled.div`
  display: block;
  .intro {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 360px);
    margin-right: 30px;
  }
  .side {
    display: inline-block;
    vertical-align: top;
    width: 330px;
    .button {
      margin-bottom: 46px;
      > span.linkWrapper {
        width: 100%;
      }
      a,
      button {
        width: 100%;
        justify-content: space-between;
      }

      @media screen and (max-width: 600px) {
        svg {
          display: none;
        }
      }
    }
    > div {
      margin-bottom: 24px;
      padding-right: 10px;
    }
  }
  .cta {
    padding: 40px 0 69px;
  }

  @media screen and (max-width: 1000px) {
    .intro,
    .side {
      width: 100% !important;
    }
  }
`

const BodyArticle = ({
  title,
  text,
  images,
  link = null,
  lists = [],
  isFirst = true,
}) => {
  return (
    <WidthContainer variant="small">
      <Wrapper>
        <div className="intro">
          <SectionElement
            title={title}
            text={text}
            images={images}
            isFirst={isFirst}
          />
        </div>
        <div className="side">
          {link && (
            <div className="button">
              <ButtonElement
                variant="primaryLink"
                href={link.href || link.external_url}
                isExternal={link.external_url}
              >
                {link.text}
              </ButtonElement>
            </div>
          )}

          {lists &&
            lists?.map((list, i) => {
              return (
                <InfoLinkList {...list?.value} key={`list-item-${i + 1}`} />
              )
            })}
        </div>
      </Wrapper>
    </WidthContainer>
  )
}

export default BodyArticle
