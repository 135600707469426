import React from 'react'
import styled from 'styled-components'
import { grey, lightgrey_background } from '../../colors'

import ListItemElement from '../../elements/listitem'
import ColorBar from '../../elements/colorbars'

const Wrapper = styled.div`
  border: 1px solid ${grey};
  border-bottom: 0;

  .links {
    background: ${lightgrey_background};
    padding: 38px 44px 40px;
    margin: 0;

    @media screen and (max-width: 1000px) {
      padding: 20px;
    }
  }
`

const LinkList = ({ links }) => {
  return (
    <Wrapper>
      <ul className="links">
        {links?.map((link, ind) => {
          return (
            <ListItemElement
              key={`link-item-list-${ind}`}
              {...link}
              variant="arrow"
            />
          )
        })}
      </ul>
      <div className="barContainer">
        <ColorBar variant="extrasmall" />
      </div>
    </Wrapper>
  )
}

export default LinkList
