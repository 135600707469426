'use client'
import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../elements/typography'
import ButtonElement from '../../elements/buttons'

import SkewContainer from '../../elements/skewWrapper'
import useWindowSize from '../../utils/useResize'

const SkewedButton = styled.a`
  position: relative;

  .container {
    position: relative;
    height: 76px;
    padding: 14px 0;
  }

  .textContainer {
    position: absolute;
    width: 100%;
    padding-right: 80px;
    text-align: center;
    padding-top: 0px;

    @media screen and (max-width: 780px) {
      padding-right: 30px;
    }
  }
  .buttonContainer {
    position: absolute;
    right: -32px;
    top: 6px;

    @media screen and (max-width: 480px) {
      display: none;
    }
  }
`

const NonSkewedComposition = styled.div`
  h3 {
    white-space: normal;
  }
`

const SkewedCardComposition = ({ text, href }) => {
  const { pageWidth } = useWindowSize()

  const isSmall = pageWidth <= 750
  if (isSmall) {
    return (
      <NonSkewedComposition className="container">
        <div className="textContainer">
          <TypographyElement variant="searchCardTitle">
            {text}
          </TypographyElement>
        </div>
      </NonSkewedComposition>
    )
  }
  return (
    <SkewContainer
      displayBlock
      skew="-18.88deg"
      style={`
            background: #fff;
            display: block;
            height: 76px;
            width: 100%;
            border: 1px solid rgba(0,0,0,0.4);

            @media screen and (max-width: 740px) {
                height: 130px;
                margin-left: -80px;
                width: 180%;
            }

            @media screen and (max-width: 470px) {
                height: 220px;
                margin-left: -80px;
                width: 180%;
            }
        `}
      containerStyling={`
            padding: 0;
            width: 100%;
            height: 80px;
            padding: 0px 50px;
            overflow: hidden;

            div.skewWrapper {
                padding: 0;
                width: 100%;
                height: 74px;
            }

            @media screen and (max-width: 740px) {
                height: 134px;
                padding: 0;
            }

            @media screen and (max-width: 470px) {
                height: 224px;
            }
        `}
    >
      <SkewedButton href={href}>
        <div className="container">
          <div className="textContainer">
            <TypographyElement variant="searchCardTitle" component="h2">
              {text}
            </TypographyElement>
          </div>
        </div>
        <div className="buttonContainer">
          <SkewContainer
            containerStyling={`
                        height: 76px;
                        width: 79px;
                        max-height: 76px;
                        padding: 0;

                        div.skewWrapper {
                            padding: 0 !important;
                            height: 76px;
                            max-height: 76px;
                            width: 90px;
                        }

                        .svgContainer {
                            position: relative;
                            path {
                                stroke: #000 !important;
                            }
                        }

                        a {
                            margin: 0 !important;
                            background: none!important;
                        }
                    `}
            style={`
                        background: none;                       
                    `}
            skew="-18.88deg"
          >
            <ButtonElement variant="arrow" noLink />
          </SkewContainer>
        </div>
      </SkewedButton>
    </SkewContainer>
  )
}

export default SkewedCardComposition
