import React from 'react'
import WidthContainer from '../../elements/widthContainer'
import EventCalendarBlock from '../../modules/eventCalendar'

const EventCalendarHome = ({ title, events, button, hideButton = false }) => {
  return (
    <WidthContainer variant="small">
      <EventCalendarBlock
        title={title}
        events={events}
        button={button}
        hideButton={hideButton}
      />
    </WidthContainer>
  )
}

export default EventCalendarHome
