import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../elements/typography'

import SkewContainer from '../../elements/skewWrapper'
import { blue } from '../../colors'
import { innerHtml } from '../../utils/innerHtml'

const SkewedContainer = styled.div`
  .container {
    position: relative;
    padding: 33px 60px;

    @media screen and (max-width: 740px) {
      background: ${blue};
      padding: 20px;
    }
  }

  .textContainer {
    width: 100%;
    text-align: left;
    padding-top: 0px;
  }
`

const SkewedTextComposition = ({ text }) => {
  return (
    <SkewContainer
      displayBlock
      unskewMobile
      skew="-18.88deg"
      style={`
            background: ${blue};
            display: block;
            width: 100%;
            
            @media screen and (max-width: 740px) {
                margin-left: -80px;
                width: 180%;
            }
        `}
      containerStyling={`
            padding: 0;
            width: 100%;
            padding: 0px 50px;
            overflow: hidden;

            @media screen and (max-width: 740px) {
                padding: 0;
            }

            div.skewWrapper {
                padding: 0;
                width: 100%;
            }
        `}
    >
      <SkewedContainer>
        <div className="container">
          <div className="textContainer">
            <TypographyElement variant="cursiveParagraph">
              {innerHtml(text)}
            </TypographyElement>
          </div>
        </div>
      </SkewedContainer>
    </SkewContainer>
  )
}

export default SkewedTextComposition
