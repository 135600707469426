import React from 'react'
import styled from 'styled-components'
import WidthContainer from '../../elements/widthContainer'
import SearchCard from '../../compositions/searchCtaLight'

const Wrapper = styled.div`
  padding-bottom: ${(props) => `${props.padding}px`};
  ${(props) => props.extraStyling};
`

const SkewedCta = ({
  title,
  text,
  href,
  image,
  buttonUpperRight = true,
  padding = 0,
  addShadow = true,
  extraStyling = {},
}) => {
  return (
    <WidthContainer variant="semi">
      <Wrapper padding={padding} extraStyling={extraStyling}>
        <SearchCard
          title={title}
          text={text}
          href={href}
          img={image}
          buttonUpperRight={buttonUpperRight}
          addShadow={addShadow}
        />
      </Wrapper>
    </WidthContainer>
  )
}

export default SkewedCta
