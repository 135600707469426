import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  ${(props) =>
    props.aspectRatio
      ? `
        aspect-ratio: ${props.aspectRatio};
    `
      : `
        padding-top: 48.65%;
    `}
  margin-bottom: 50px;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: ${(props) => props.iframeWidth};
    height: 100%;
  }

  @media screen and (max-width: 1200px) {
    padding-top: 58.65%;
    aspect-ratio: unset;
    iframe {
      width: 100%;
    }
  }
`

const Iframe = ({ src, title, aspectRatio = 0, width = '100' }) => {
  // Get the proper multiplier
  const multiplier = width ? parseInt(width) / 100 : 1
  // Get iframe width in percentage
  const iframeWidth = width ? `${width}%` : '100%'
  // Adjust the aspect ratio for the iframe width
  const adjustedAspectRatio = aspectRatio / multiplier

  return (
    <Wrapper iframeWidth={iframeWidth} aspectRatio={adjustedAspectRatio}>
      <iframe
        src={src}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Wrapper>
  )
}

export default Iframe
