// HEAVILY WIP
import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'

import { StaticGoogleMap, Marker } from 'react-static-google-map'

const MapsWrapper = styled.div`
  width: 100%;
`

const GoogleMapModule = ({ width = 600, height = 600, locations }) => {
  if (locations.length > 1) {
    return (
      <MapsWrapper>
        <StaticGoogleMap
          size={`${width}x${height}`}
          apiKey="AIzaSyDRzjCwZbOUwluc8pAdAQQV5AlwQv4rliU"
        >
          <Marker.Group label="T" color="red">
            {locations?.map((item) => (
              <Marker key={item} location={item} />
            ))}
          </Marker.Group>
        </StaticGoogleMap>
      </MapsWrapper>
    )
  }

  return (
    <MapsWrapper>
      <StaticGoogleMap
        size={`${width}x${height}`}
        apiKey="AIzaSyDRzjCwZbOUwluc8pAdAQQV5AlwQv4rliU"
      >
        <Marker location={locations[0]} />
      </StaticGoogleMap>
    </MapsWrapper>
  )
}

export default GoogleMapModule
