import React from 'react'
import styled from 'styled-components'

import ButtonElement from '../../elements/buttons'
import InfoLinkList from '../../compositions/infoLinksList'

import WidthContainer from '../../elements/widthContainer'
import { innerHtml } from '../../utils/innerHtml'
import TypographyElement from '../../elements/typography'
import ClubInfo from '../../compositions/clubInfo'
import TextCta from '../../compositions/textCta'
import { SectionElement } from '../../compositions/articleSection'
import TableSection from '../table'

const Wrapper = styled.div`
  display: block;
  .intro {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 360px);
    margin-right: 30px;
  }
  .side {
    display: inline-block;
    vertical-align: top;
    width: 330px;
    .button {
      margin-bottom: 46px;
      > span.linkWrapper {
        width: 100%;
      }
      a,
      button {
        width: 100%;
        justify-content: space-between;
      }

      @media screen and (max-width: 600px) {
        svg {
          display: none;
        }
      }
    }
    > div {
      margin-bottom: 24px;
      padding-right: 10px;
    }
  }
  .cta {
    padding: 40px 0 69px;
  }

  @media screen and (max-width: 1000px) {
    .intro,
    .side {
      width: 100% !important;
    }
  }
`

const mapElements = {
  section: SectionElement,
  prices: ClubInfo,
  table: TableSection,
}

const LocationIntro = ({
  intro,
  body,
  link,
  lists,
  cta,
  isClub,
  infoRows,
  addresses,
  firstH1 = true,
}) => {
  return (
    <WidthContainer variant="small">
      <Wrapper>
        <div className="intro">
          {intro?.map((item, index) => {
            if (item.type == 'prices') {
              return null
            }
            const Section = mapElements[item.type]
            return (
              <Section
                {...item.value}
                key={`intro-section-${item.id}`}
                bottomSpacing={0}
                isFirst={index === 0 && firstH1}
              />
            )
          })}
          {isClub && infoRows ? <ClubInfo title="" rows={infoRows} /> : null}
          {body?.map((item) => {
            if (item.type == 'prices') {
              return null
            }
            const Section = mapElements[item.type]
            return (
              <Section
                {...item.value}
                key={`intro-section-${item.id}`}
                bottomSpacing={0}
              />
            )
          })}
        </div>
        <div className="side">
          {link && (
            <ButtonElement
              className="button"
              handleClick={() => {
                const url = link?.external_url || link?.href

                if (isClub && window.dataLayer) {
                  window.dataLayer.push({
                    event: 'ik-wil-sporten-out',
                    'ik-wil-sporten-out': url,
                  })
                }
                window.open(url, '_blank')
              }}
            >
              {link.text}
            </ButtonElement>
          )}

          {lists &&
            lists?.map((list) => {
              return <InfoLinkList {...list.value} key={list.value.title} />
            })}
          {(addresses?.links || addresses?.items) && (
            <InfoLinkList {...addresses} key={`specific-addresses`} />
          )}
        </div>
        {cta && cta != {} && (
          <div className="cta">
            <TextCta {...cta} />
          </div>
        )}
      </Wrapper>
    </WidthContainer>
  )
}

export default LocationIntro
