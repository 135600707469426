import React from 'react'
import styled from 'styled-components'

// Compositions
import Searchbar from '../../compositions/searchbar'
import Ordering from '../../compositions/ordering'
import SearchSiteCard from '../../compositions/searchSiteCard'
import InfoLinkList from '../../compositions/infoLinksList'

// Elements
import PaginationElement from '../../elements/pagination'
import TypographyElement from '../../elements/typography'
import FaqSection from '../../sections/faq'

import WidthContainer from '../../elements/widthContainer'
import { searchSiteApiClient } from '../../utils/api/fetchApi'
import { buildLocalHost } from '@/core/utils/buildHost'

const SearchWrapper = styled.div`
  display: block;
  .main {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 310px);
    padding-right: 30px;
    .filters {
      display: block;
      padding-bottom: 40px;

      h2 {
        margin: 0;
        padding: 0;
        margin-bottom: 8px;
      }

      .summary {
        display: inline-block;
        vertical-align: middle;
      }
      > * {
        display: inline-block;
        vertical-align: middle;
      }

      .order {
        width: calc(100% - 310px);
        text-align: right;
        > * {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    .results {
      display: block;
      margin-bottom: 23px;
      > * {
        margin-bottom: 20px;
      }
    }
  }
  .side {
    display: inline-block;
    vertical-align: top;
    width: 310px;
  }

  @media screen and (max-width: 900px) {
    .main {
      width: 100%;
      .filters {
        .order {
          margin: 20px 0;
          width: 100%;
        }
      }
    }
    nav {
      ul > li {
        margin-bottom: 10px;
      }
    }
  }
`

// @ToDo full filterlist
const orderOptions = [
  {
    value: 'title',
    name: 'Alfabetisch (A - Z)',
  },
  {
    value: '-title',
    name: 'Alfabetisch (Z - A)',
  },
  {
    value: 'score',
    name: 'Relevantie',
  },
]

const SearchSite = ({ perPage = 8, placeholder, sidelists, faqs }) => {
  const [hasSearched, setHasSearched] = React.useState(false)
  const [search, setSearch] = React.useState('')
  const [order, setOrder] = React.useState('score')
  const [results, setResults] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [count, setCount] = React.useState(0)
  const [pages, setPages] = React.useState(1)

  const host = buildLocalHost()

  const getData = async ({
    pageInput = null,
    orderInput = null,
    searchInput = null,
  }) => {
    const query = {
      page: pageInput || page,
      per_page: perPage,
      order: orderInput || order,
      query: searchInput || search,
    }

    const data = await searchSiteApiClient(host, query)
    const { search_results } = data || {}

    setResults(search_results?.pages)
    setPages(search_results?.num_pages || 1)
    setCount(search_results?.count || 0)
  }

  const handleOrderChange = (event) => {
    const value = event.target.value
    setOrder(value)
    getData({
      orderInput: value,
    })
  }
  const handlePaginationChange = (event, value) => {
    setPage(value)
    getData({
      pageInput: value,
    })
  }

  const handleSearch = () => {
    if (!search) return null
    getData({
      searchInput: search,
      pageInput: 1,
    })
    setHasSearched(true)
  }

  const currentResults = `${count || 0} resultaten gevonden`

  return (
    <SearchWrapper>
      {!hasSearched ? (
        <>
          <WidthContainer variant="small">
            <Searchbar
              placeholder={placeholder}
              value={search}
              setValue={setSearch}
              handleSubmit={handleSearch}
              variant="large"
            />
          </WidthContainer>
          {faqs?.length ? (
            <WidthContainer variant="semi">
              <FaqSection title="FAQ" questions={faqs} />
            </WidthContainer>
          ) : null}
        </>
      ) : (
        <WidthContainer variant="full">
          <div className="main">
            <div className="filters">
              <Searchbar
                placeholder={placeholder}
                value={search}
                setValue={setSearch}
                handleSubmit={handleSearch}
              />

              <div className="order">
                <TypographyElement variant="subtitle">
                  {currentResults}
                </TypographyElement>
                <Ordering
                  order={order}
                  handleOrderChange={handleOrderChange}
                  orderOptions={orderOptions}
                />
              </div>
            </div>

            <div className="results">
              {results?.map((result, index) => {
                return (
                  <SearchSiteCard
                    {...result}
                    description={result.search_description}
                    key={`result-${index}-${result.title}`}
                  />
                )
              })}
            </div>
            {pages > 1 && (
              <PaginationElement
                page={page}
                count={pages}
                handleChange={handlePaginationChange}
              />
            )}
          </div>
          <div className="side">
            {sidelists?.map((list) => {
              return <InfoLinkList {...list} key={list.title} />
            })}
          </div>
        </WidthContainer>
      )}
    </SearchWrapper>
  )
}

export default SearchSite
