import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../elements/typography'
import ButtonElement from '../../elements/buttons'
import {
  grey_background,
  secondary_green,
  white,
  quarternary_black,
} from '../../colors'

const Wrapper = styled.div`
  padding: 24px 35px;
  background: ${white};
  box-shadow: 0px 17px 98px rgba(0, 0, 0, 0.05),
    0px 3.79717px 21.8896px rgba(0, 0, 0, 0.0298054),
    0px 1.13052px 6.5171px rgba(0, 0, 0, 0.0201946);

  .textContainer {
    display: inline-block;
    vertical-align: middle;
    margin-right: 45px;
  }
`

const TabWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 28px;
  padding: 10px 49px;
  color: ${quarternary_black};
  background-color: ${white};
  border: 1px solid #e3e3e3;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: ${grey_background};
  }
  &:focus {
    background-color: ${grey_background};
  }

  ${(props) =>
    props.selected &&
    `
        color: ${white} !important;
        background-color: ${secondary_green};
        border-color: ${secondary_green};
        cursor: unset;
        &:hover {
            background-color: ${secondary_green};
        }
        &:focus {
            background-color: ${secondary_green};
        }
    `}
`

const Tab = ({ filter, handleFilter, selected }) => {
  return (
    <TabWrapper selected={selected} onClick={() => handleFilter(filter.value)}>
      <TypographyElement variant="tabText">{filter.name}</TypographyElement>
    </TabWrapper>
  )
}

const OpeningSchemaFilter = ({ title, filters, handleFilter, selected }) => {
  return (
    <Wrapper>
      <div className="textContainer">
        <TypographyElement variant="tabText" component="span">
          {title}:
        </TypographyElement>
      </div>
      {filters?.map((filter, index) => {
        return (
          <Tab
            key={`filters-list-${index}`}
            handleFilter={handleFilter}
            filter={filter}
            selected={selected == filter.value}
          />
        )
      })}
    </Wrapper>
  )
}

export default OpeningSchemaFilter
