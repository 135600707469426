import React from 'react'
import WidthContainer from '../../elements/widthContainer'
import ListsBlock from '../../modules/listsBlock'

const BodyListColumns = ({ column1, column2, column3 }) => {
  return (
    <WidthContainer variant="small">
      <ListsBlock lists={[column1, column2, column3]} />
    </WidthContainer>
  )
}

export default BodyListColumns
