import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material'

import NewsCard from '../../compositions/newsCard'
import WidthContainer from '../../elements/widthContainer'

import TypographyElement from '../../elements/typography'
import ButtonElement from '../../elements/buttons'

const Container = styled.div`
  background: ${(props) => props.background};
  padding: 70px 220px;

  @media screen and (max-width: 1680px) {
    padding: 64px 100px;
  }

  @media screen and (max-width: 1380px) {
    padding: 64px 50px;
  }

  @media screen and (max-width: 1000px) {
    padding: 34px 20px;
  }

  .news {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .button {
    text-align: right;
    a {
      text-align: center;
    }

    @media screen and (max-width: 600px) {
      svg {
        display: none;
      }
    }
  }
`

const NewsSection = ({
  title,
  news,
  button,
  first_large,
  hasBackground = true,
}) => {
  const theme = useTheme()

  return (
    <WidthContainer variant="full">
      <Container
        background={
          hasBackground
            ? theme?.globalTheme?.palette?.backgroundColor?.main
            : 'transparent'
        }
      >
        <TypographyElement variant="moreTitle">{title}</TypographyElement>
        <div className="news">
          {news?.map((item, index) => {
            return (
              <NewsCard
                key={`news-card-secta-${index}`}
                {...item}
                decorativeOnly={true}
                isLarge={index == 0 && first_large}
              />
            )
          })}
        </div>
        {button?.href && (
          <div className="button">
            <ButtonElement variant="primaryLink" href={button.href}>
              {button.text}
            </ButtonElement>
          </div>
        )}
      </Container>
    </WidthContainer>
  )
}

export default NewsSection
