import React from 'react'

import WidthContainer from '../../elements/widthContainer'
import FAQBlock from '../../modules/faq'

const FaqSection = ({ title = 'FAQ', questions }) => {
  return (
    <WidthContainer variant="semi">
      <FAQBlock title={title} questions={questions} />
    </WidthContainer>
  )
}

export default FaqSection
