import React from 'react'
import styled from 'styled-components'
import WidthContainer from '../../elements/widthContainer'
import Maps from '../../modules/mapsStatic'
import DynamicMaps from '../../modules/maps'

const MapContainer = styled.div`
  text-align: center;
`

const CustomDynamnicMap = styled.div`
  width: 100%;
  height: 600px;
  overflow: hidden;
`

const DEFAULT_CENTER = {
  lat: 53.18,
  lng: 6.567086535707376,
}

const getCenter = (coordinates) => {
  if (!coordinates?.length) {
    // Default center
    return DEFAULT_CENTER
  }
  const lats = coordinates?.map((item) => item.cords?.[0].lat)
  const lat = lats.reduce((a, b) => a + b) / lats.length
  const lngs = coordinates?.map((item) => item.cords?.[0].lng)
  const lng = lngs.reduce((a, b) => a + b) / lngs.length
  if (lat && lng) {
    return {
      lat,
      lng,
    }
  }
  return DEFAULT_CENTER
}

const StaticMapSection = ({
  width,
  title,
  height,
  markersOnly,
  locations,
  isStatic,
}) => {
  const [render, setRender] = React.useState(false)

  // Lazy loader
  React.useEffect(() => {
    setTimeout(() => {
      setRender(true)
    }, 500)
  }, [])

  if (!render) {
    return null
  }

  if (!isStatic) {
    const dynamicLocations = locations.map((location) => {
      return {
        cords: [
          {
            lat: parseFloat(location.split(',')[0]),
            lng: parseFloat(location.split(',')[1]),
          },
        ],
        title: title || 'Location',
      }
    })
    const center = getCenter(dynamicLocations)

    return (
      <CustomDynamnicMap>
        <DynamicMaps
          data={dynamicLocations}
          defaultZoom={13}
          defaultCenter={center}
          markersOnly={markersOnly}
        />
      </CustomDynamnicMap>
    )
  }

  return (
    <WidthContainer variant="semi">
      <MapContainer>
        <Maps width={width} height={height} locations={locations} />
      </MapContainer>
    </WidthContainer>
  )
}

export default StaticMapSection
