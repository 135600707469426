import React from 'react'
import styled from 'styled-components'

import WidthContainer from '../../elements/widthContainer'
import SearchSite from '../../modules/searchSite'
import InfoLinkList from '../../compositions/infoLinksList'

const Wrapper = styled.div`
  display: block;
  width: 100%;
  .main {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 410px);
    margin-right: 30px;

    @media screen and (max-width: 1000px) {
      width: 100%;
      padding: 0;
      margin-top: 10px;
    }
  }
  .side {
    display: inline-block;
    vertical-align: top;
    width: 410px;
    > * {
      display: block;
    }

    @media screen and (max-width: 1000px) {
      width: 100%;
      padding: 0;
      margin-top: 40px;
    }
  }
`

const SearchSection = ({
  sidelists,
  perPage = 8,
  totalCount,
  placeholder,
  faqs,
}) => {
  return (
    <WidthContainer variant="semi">
      <SearchSite
        // results={results}
        perPage={perPage}
        totalCount={totalCount}
        placeholder={placeholder}
        sidelists={sidelists}
        faqs={faqs}
      />
    </WidthContainer>
  )
}

export default SearchSection
