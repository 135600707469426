'use client'
import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../../elements/typography'
import ActivityCard from '../../../compositions/_subsite/activityCard'
import ButtonElement from '../../../elements/buttons'
import { button_text_black } from '../../../colors'

const Wrapper = styled.div`
  padding: 92px 0 76px;
  .itemsContainer {
    display: block;
    > div {
      display: inline-block;
      vertical-align: middle;
      margin-right: 30px;
      height: unset;
      width: calc(33.3% - 20px);
      aspect-ratio: 0.86;

      // @media screen and (max-width: 1100px) {
      //   width: 100%;
      //   max-width: 100%;
      //   display: block;
      //   margin-right: 0;
      //   margin-bottom: 20px;
      //   aspect-ratio: initial;
      //   height: 300px;
      // }
    }
  }
  .buttonContainer {
    margin-top: 67px;
    display: block;
    text-align: right;
  }
`

const ActivitiesBlock = ({ title, items, link }) => {
  return (
    <Wrapper>
      <TypographyElement variant="title" component="h2">
        {title}
      </TypographyElement>
      <div className="itemsContainer">
        {items?.map((item, i) => {
          return <ActivityCard {...item} key={`activity-${i}-${item.text}`} condensedMode />
        })}
      </div>
      <div className="buttonContainer">
        <ButtonElement
          variant="baseArrowLink"
          href={link.href || link.external_url}
          color={button_text_black}
        >
          {link.text}
        </ButtonElement>
      </div>
    </Wrapper>
  )
}

export default ActivitiesBlock
