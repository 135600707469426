import React from 'react'
import styled from 'styled-components'
import ColoredLinkBlock from '../../compositions/coloredLinkBlock'
import WidthContainer from '../../elements/widthContainer'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  ${(props) =>
    props.negativeMargin
      ? `
        margin-top: -114px;
    `
      : ``}

  @media screen and (max-width: 1050px) {
    display: block;

    > div {
      width: 100%;
      margin-bottom: 50px;
    }
  }
`

const ColoredLinksSections = ({ blocks, index }) => {
  if (!blocks || blocks.length == 0) {
    return null
  }
  return (
    <WidthContainer variant="semi">
      <Wrapper negativeMargin={index === 0}>
        {blocks?.map((block, i) => {
          return <ColoredLinkBlock {...block} key={`${block.title}-${1}`} />
        })}
      </Wrapper>
    </WidthContainer>
  )
}

export default ColoredLinksSections
