import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../elements/typography'
import OpeningtimeRow from '../../compositions/openingtimerow'
import { white } from '../../colors'
import { getMomentTz } from '../../utils/getMomentTz'

const Container = styled.div`
  padding: 40px 47px;
  background-color: ${white};
  box-shadow: 0px 17px 98px rgba(0, 0, 0, 0.05),
    0px 3.79717px 21.8896px rgba(0, 0, 0, 0.0298054),
    0px 1.13052px 6.5171px rgba(0, 0, 0, 0.0201946);
  .results {
    padding: 35px 0 30px;
    > div {
      padding-bottom: 25px;
    }
  }
`

const OpeningSchemaOverview = ({ selectedDay, results }) => {
  const day = getMomentTz(selectedDay)
  const dateString = day.format('D MMMM YYYY')
  const dayString = day.isSame() ? 'Vandaag' : day.format('dddd')

  const title = `${dayString} ${dateString}`
  return (
    <Container>
      <TypographyElement variant="schemaResultTitle">{title}</TypographyElement>
      <div className="results">
        {results?.map((result, i) => {
          return <OpeningtimeRow {...result} key={`${result.title}-${i}`} />
        })}
      </div>
    </Container>
  )
}

export default OpeningSchemaOverview
