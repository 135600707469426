import React from 'react'
import LinkListImageBlock from '../../modules/linklistImage'

import WidthContainer from '../../elements/widthContainer'

const LinkListImageSection = (props) => {
  return (
    <WidthContainer variant="small">
      <LinkListImageBlock {...props} />
    </WidthContainer>
  )
}

export default LinkListImageSection
