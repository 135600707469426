import React from 'react'
import styled from 'styled-components'

import Logo from '../../elements/logo'
import ButtonElement from '../../elements/buttons'
import ImageBuilder from '../../elements/imageBuilder'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  aspect-ratio: 2.52;
  position: relative;
  text-align: center;

  .imageblock,
  .contentblock {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
  }

  .imageblock {
    img {
      object-fit: cover !important;
    }
  }

  .contentblock {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100%;
    aspect-ratio: 2.52;
    position: relative;
    text-align: center;

    @media screen and (max-width: 1000px) {
      padding: 80px 20px;
    }

    > * {
      display: block;
      :first-child {
        max-width: 300px;
        margin-bottom: 32px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
`

const HomeCta = ({ link, img }) => {
  return (
    <Wrapper>
      <div className="imageblock">
        <ImageBuilder
          decorativeOnly={true}
          priority
          image={img}
          layout="fill"
        />
      </div>
      <div className="contentblock">
        <Logo variant="white" href={null} />
        <ButtonElement
          variant="whiteLink"
          href={link.href || link.external_url}
        >
          {link.text}
        </ButtonElement>
      </div>
    </Wrapper>
  )
}

export default HomeCta
