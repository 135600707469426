import React from 'react'
import styled from 'styled-components'
import { quarternary_black, red } from '../../colors'

import TypographyElement from '../../elements/typography'

const Container = styled.div`
  .timeslot {
    display: inline-block;
    vertical-align: middle;
    margin-right: 46px;
    background-color: ${red};
    padding: 0 9px;
  }
  .title {
    display: inline-block;
    vertical-align: middle;
    margin-right: 19px;
  }
  .description {
    display: inline-block;
    vertical-align: middle;
    color: ${quarternary_black} !important;
  }
`

const OpeningtimeRow = ({ start, end, title, description }) => {
  return (
    <Container>
      <div className="timeslot">
        <TypographyElement variant="openingtimeTimeslot">
          {start}u - {end}u
        </TypographyElement>
      </div>
      <div className="title">
        <TypographyElement variant="tabText" component="span">
          {title}
        </TypographyElement>
      </div>
      <div className="description">
        <TypographyElement variant="footerColumnItem" component="span">
          ({description})
        </TypographyElement>
      </div>
    </Container>
  )
}

export default OpeningtimeRow
