'use client'
import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material'

import WidthContainer from '../../elements/widthContainer'
import ActivitiesBlock from '../../modules/_subsite/activities'

const ActivitiesSection = ({
  title,
  activities,
  link,
  hasBackground = true,
}) => {
  const theme = useTheme()
  return (
    <WidthContainer
      variant="semi"
      background={
        hasBackground ? theme?.globalTheme?.palette?.backgroundColor?.main : ''
      }
    >
      <ActivitiesBlock title={title} items={activities} link={link} />
    </WidthContainer>
  )
}

export default ActivitiesSection
