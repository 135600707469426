import React from 'react'
import styled from 'styled-components'

import WidthContainer from '../../elements/widthContainer'
import SkewedTextComposition from '../../compositions/skewedTextBlock'
import SkewedCardComposition from '../../compositions/skewedButton'
import ImageBuilder from '../../elements/imageBuilder'

const Wrapper = styled.div`
  background-position: center;
  background-size: cover;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 700px;
  margin: 60px 0;

  > div.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover !important;
    }
  }

  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    > div {
      padding-top: 201px;
      padding-bottom: 157px;
      margin: 0 auto;
      max-width: 890px;
      width: 100%;
      > :first-child {
        margin-bottom: 26px;
        margin-left: 28px;

        @media screen and (max-width: 740px) {
          margin-left: 0;
        }
      }
      > :nth-child(2) {
        margin-left: -28px;

        @media screen and (max-width: 740px) {
          margin: 0;
        }
      }
    }
  }
  @media screen and (max-width: 700px) {
    margin: 0;
    display: block;
    height: auto;
    .bg {
      position: relative !important;
      display: block !important;
      height: 300px !important;
    }
    .content {
      position: relative !important;
      display: block !important;
      > div {
        padding: 0;
        .skewWrapper {
          ::before {
            height: 100%;
          }
          .svgContainer {
            display: none;
          }
        }
        > div {
          height: 100%;
        }
      }
    }
  }

  a {
    display: block;
  }
`

const CtaSection = ({ cta, text, image }) => {
  return (
    <WidthContainer variant="full">
      <Wrapper>
        {image.url && (
          <div className="bg">
            <ImageBuilder image={image.url} layout="fill" />
          </div>
        )}
        <div className="content">
          <div>
            <SkewedCardComposition {...cta} />
            <SkewedTextComposition text={text} />
          </div>
        </div>
      </Wrapper>
    </WidthContainer>
  )
}

export default CtaSection
