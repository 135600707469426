import React from 'react'
import styled from 'styled-components'

import Field, { FormRow } from '../../elements/fields'
import TypographyElement from '../../elements/typography'
import { Form } from '../../elements/form'
import ButtonElement from '../../elements/buttons'

import { validation } from '../../utils/formValidation'
import CSRFToken from '../../elements/csrf'
import { postFormApi } from '../../utils/api/fetchApi'
import SuccessBlock from '../../compositions/succesBlock'

const Wrapper = styled.div`
  .buttonContainer {
    text-align: right;
    width: 60%;
    min-width: 761px;
    padding-top: 11px;

    @media screen and (max-width: 1050px) {
      min-width: 0px;
      width: 100%;
      text-align: left;
      padding-bottom: 30px;
      margin-bottom: 30px;
    }

    @media screen and (max-width: 740px) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
`

const SignUpForm = ({
  sendTo,
  formType,
  title = 'Aanmeldformulier',
  pagetitle,
  host,
}) => {
  const [values, setValues] = React.useState({
    to: sendTo,
    type: formType,
    event: pagetitle,
  })
  const [errors, setErrors] = React.useState({})
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState(false)

  const disabled = !(
    values['name'] &&
    values['teamname'] &&
    values['email'] &&
    values['phone'] &&
    Object.keys(errors).length == 0
  )

  const handleAllValidation = (entries) => {
    const newErrors = {}
    entries.forEach((entry) => {
      const name = entry[0]
      const value = entry[1] == 'placeholder' ? '' : entry[1]
      const error = validation(value, name, 'addevent')
      if (error) {
        newErrors[name] = error
      }
    })
    return newErrors
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const csrftoken = e.target.csrfmiddlewaretoken.value
    const data = new FormData(e.target)
    const entries = [...data.entries()]

    const errorState = handleAllValidation(entries)
    setErrors(errorState)
    if (Object.keys(errors).length == 0) {
      postFormApi(host, 'api/postform/', entries, csrftoken)
        .then((results) => {
          setSuccess(true)
        })
        .catch((error) => {
          setError('Er is iets misgegaan!')
        })
    }
  }

  const handleChange = (value, name) => {
    if (errors && Object.keys(errors).length > 0) {
      const errorState = handleValidationErrors(value, name)
      setErrors(errorState)
    }
    setValues({ ...values, [name]: value })
  }

  const handleValidationErrors = (value, name) => {
    const error = validation(value, name, 'signup')

    let errorState = { ...errors }
    if (!error && name in errors) {
      delete errorState[name]
    } else if (error) {
      errorState[name] = error
    }
    return errorState
  }

  const handleBlur = (e) => {
    const { value, name } = e.target
    const errorState = handleValidationErrors(value, name)
    setErrors(errorState)
  }

  return (
    <Wrapper>
      {success ? (
        <SuccessBlock
          title="Aanmelding verzonden"
          text="Uw aanmelding is verzonden! U ontvangt binnen 2 dagen een e-mail met goedkeuring"
        />
      ) : (
        <Form handleSubmit={handleSubmit}>
          <TypographyElement variant="title">{title}</TypographyElement>
          <CSRFToken />
          <FormRow>
            <Field
              variant="text"
              label="Naam"
              required
              defaultValue=""
              placeholder=""
              value={values['name']}
              handleChange={(e) => {
                handleChange(e.target.value, e.target.name)
              }}
              handleBlur={handleBlur}
              id="name"
              name="name"
              width="mid"
              error={errors['name']}
            />
          </FormRow>
          <FormRow>
            <Field
              variant="text"
              label="Teamnaam"
              required
              defaultValue=""
              placeholder=""
              value={values['teamname']}
              handleChange={(e) => {
                handleChange(e.target.value, e.target.name)
              }}
              handleBlur={handleBlur}
              id="teamname"
              name="teamname"
              width="mid"
              error={errors['teamname']}
            />
          </FormRow>
          <FormRow>
            <Field
              variant="email"
              label="E-mailadres"
              required
              defaultValue=""
              placeholder="sport@050.nl"
              value={values['email']}
              handleChange={(e) => {
                handleChange(e.target.value, e.target.name)
              }}
              handleBlur={handleBlur}
              id="email"
              name="email"
              width="mid"
              error={errors['email']}
            />
          </FormRow>
          <FormRow>
            <Field
              variant="telephone"
              label="Telefoonnummer"
              required
              defaultValue=""
              placeholder="050 123 4567"
              value={values['phone']}
              handleChange={(e) => {
                handleChange(e.target.value, e.target.name)
              }}
              handleBlur={handleBlur}
              id="phone"
              name="phone"
              width="mid"
              error={errors['phone']}
            />
          </FormRow>
          <FormRow>
            <div className="buttonContainer">
              <ButtonElement
                variant="primary"
                type="submit"
                disabled={disabled}
              >
                Verzenden
              </ButtonElement>
            </div>
          </FormRow>
        </Form>
      )}
    </Wrapper>
  )
}

export default SignUpForm
