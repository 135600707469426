import React from 'react'
import styled from 'styled-components'
import { lightgrey, quarternary_black } from '../../colors'
import TypographyElement from '../../elements/typography'

const DetailsWrapper = styled.div`
  background-color: ${lightgrey};
  padding: 8px 27px;
  margin: 0 0 9px;
  display: block;
  max-width: 546px;

  @media screen and (max-width: 1000px) {
    max-width: 100%;
  }

  .text {
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    p {
      padding: 0;
    }
  }
  .infodetail {
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    text-align: right;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: ${quarternary_black};
    text-align: right;
  }
  @media screen and (max-width: 600px) {
    .text,
    .infodetail {
      display: block;
      width: 100%;
      text-align: left;
    }
  }
`

const Detail = ({ title, info }) => {
  return (
    <DetailsWrapper>
      <div className="text">
        <TypographyElement variant="clubDetails">{title}</TypographyElement>
      </div>
      <div className="infodetail">{info}</div>
    </DetailsWrapper>
  )
}

export default Detail
