import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { grey, grey_background } from '../../colors'

const ProductTable = ({ rows }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="a dense table">
        <TableHead
          variant="productTable"
          sx={{
            backgroundColor: grey_background,
            color: grey,
          }}
        >
          <TableRow>
            <TableCell sx={{ width: '10%' }}> </TableCell>
            <TableCell sx={{ width: '40%' }}>Webkassa Papiermolen</TableCell>
            <TableCell sx={{ width: '35%' }}>Omschrijving</TableCell>
            <TableCell sx={{ width: '5%' }}>Prijs</TableCell>
            <TableCell sx={{ width: '10%' }}> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, i) => (
            <TableRow key={`${i}-${row.title}`}>
              <TableCell component="th" scope="row">
                {' '}
              </TableCell>
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.price}</TableCell>
              <TableCell>IN WINKELMAND</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ProductTable
