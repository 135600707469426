import React from 'react'
import styled from 'styled-components'
import ImageBuilder from '../../elements/imageBuilder'

import TitleTextButtonCard from '../../compositions/titleTextButton'

const Wrapper = styled.div`
  width: 100%;
  display: block;

  .imageContainer {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    span {
      width: 100% !important;
      img {
        width: inherit !important;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .content {
    display: inline-block;
    vertical-align: top;
    width: 50%;
  }

  @media screen and (max-width: 1000px) {
    .imageContainer,
    .content {
      width: 100%;
      display: block;

      > div {
        padding: 2px 0 20px 0;
      }
    }
  }

  ${(props) =>
    props.variant == 'left' &&
    `
        direction: rtl;
        > * {
            direction: ltr;
        }
    `}
`

const MediaTextBlock = ({ title, text, button, image, variant = 'left' }) => {
  return (
    <Wrapper variant={variant}>
      <div className="imageContainer">
        {image && <ImageBuilder image={image} height={389} width={648} />}
      </div>
      <div className="content">
        <TitleTextButtonCard
          title={title}
          text={text}
          button={button}
          variant={variant}
        />
      </div>
    </Wrapper>
  )
}

export default MediaTextBlock
