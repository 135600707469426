import React from 'react'
import styled from 'styled-components'
import { grey_background } from '../../colors'
import LocationSquareCard from '../../compositions/locationSquareCard'
import TypographyElement from '../../elements/typography'
import WidthContainer from '../../elements/widthContainer'

const Wrapper = styled.div`
  display: block;
  padding: 70px 0 39px;
  .blocks {
    margin-bottom: 40px;
    margin-top: 40px;

    display: block;
    > * {
      display: inline-block;
      vertical-align: top;
      margin-right: 30px;
      margin-bottom: 30px;
      width: calc(33% - 20px);
      :nth-child(3n + 3) {
        margin-right: 0;
      }

      @media screen and (max-width: 900px) {
        width: 100%;
        max-width: 100%;
        margin: 0;
        display: block;
      }
    }
  }
`

const LocationSquareSection = ({ title, text, blocks }) => {
  return (
    <WidthContainer variant="semi" background={grey_background}>
      <Wrapper>
        <TypographyElement variant="title" component="h2">
          {title}
        </TypographyElement>
        <TypographyElement variant="p" component="p">
          {text}
        </TypographyElement>
        <div className="blocks">
          {blocks?.map((block, index) => {
            return (
              <LocationSquareCard key={`location-sqaure-${index}`} {...block} />
            )
          })}
        </div>
      </Wrapper>
    </WidthContainer>
  )
}

export default LocationSquareSection
