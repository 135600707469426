import React from 'react'
import WidthContainer from '../../elements/widthContainer'
import ClubInfo from '../../compositions/clubInfo'

const BodyTable = ({ title, rows }) => {
  return (
    <WidthContainer variant="small">
      <ClubInfo title={title} rows={rows} />
    </WidthContainer>
  )
}

export default BodyTable
