'use client'
import React from 'react'
import styled from 'styled-components'
import dynamic from 'next/dynamic'

import ButtonElement from '../../elements/buttons'
import InfoLinkList from '../../compositions/infoLinksList'

import WidthContainer from '../../elements/widthContainer'

import ArticleImages from '../../modules/_subsite/articleImages'
import AllFacilities from '../allFacilities'
import MainInfo from '../mainPageInfo'
import { SectionElement } from '../../compositions/articleSection'
import { blue, subsite_hover_blue } from '../../colors'
import SubsiteNewsSectionApi from '../newsSectionApi'
import Openingtimes from '../openingTimes'
import Tables from '../tabels'
import FacilityPrices from '../../modules/_subsite/facilityPrices'
import SubsiteNewsSection from '../newsSection'
import ArticleRichText from '../articleRichText'
import SubsiteShortNewsSection from '../shortNewsSection'
import ContactBlock from '../../modules/_subsite/contactBlock'
import Rates from '../rates'
import IframeSection from '../../sections/iframeSection'
import FormBuilderSection from '../../sections/FormBuilderSection'
import TypographyElement from '../../elements/typography'
import { buildLocalHost } from '@/core/utils/buildHost'

// Dynamically import since window is required
const DynamicShare = dynamic(() => import('../../modules/_subsite/share'), {
  ssr: false,
})

const Wrapper = styled.div`
  display: block;
  flex-direction: row;
  .intro {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 440px);
    margin-right: 30px;
    .imagesContainer {
      width: calc(100% + 440px) !important;
      span {
        overflow: visible !important;
        img {
          object-fit: cover;
        }
      }
    }

    @media screen and (max-width: 1000px) {
      width: 100%;
      padding: 0;
      margin-top: 10px;
      .imagesContainer {
        width: 100% !important;
      }
    }
  }
  .side {
    position: relative;
    z-index: 9;
    display: inline-block;
    vertical-align: top;
    width: 410px;

    @media screen and (max-width: 1300px) {
      width: 100%;
      padding: 0;
      margin-top: 0;
    }
    .button {
      margin-bottom: 46px;
      ${(props) =>
        props.useBlueButton &&
        `
                a {
                    background-color: ${blue};
                    :hover {
                        background-color: ${subsite_hover_blue};
                    }
                }
            `}

      @media screen and (max-width: 600px) {
        svg {
          display: none;
        }
      }
    }
    > div {
      margin-bottom: 24px;
    }
  }
  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    .intro {
      order: 2;
    }
    .side {
      order: 1;
    }
  }
`

const mapElements = {
  images: ArticleImages,
  facilities: AllFacilities,
  openingtimes: MainInfo,
  news: SubsiteNewsSection,
  prices: FacilityPrices,
  text: ArticleRichText,
  facilityprices: FacilityPrices,
  tables: Tables,
  shortnews: SubsiteShortNewsSection,
  contact: ContactBlock,
  share: DynamicShare,
  section: SectionElement,
  rates: Rates,
  embed: IframeSection,
  form: FormBuilderSection,
}

const ArticleIntro = ({
  intro,
  link,
  lists,
  news = null,
  facilities = null,
  activities = null,
  useBlueButton = false,
  slug = '',
  showOpeningtimes = false,
  isActivities = false,
  pageID = '',
  activityID = '',
  categories,
  sitename = '',
  title = '',
}) => {
  const host = buildLocalHost()
  return (
    <WidthContainer variant="semi">
      <Wrapper useBlueButton={useBlueButton}>
        <div className="intro">
          {title && (
            <TypographyElement variant="title">{title}</TypographyElement>
          )}
          {intro?.map((item, i) => {
            if (!(item?.type in mapElements)) return null
            const Section = mapElements[item.type]
            return <Section {...item.value} key={`intro-sectin-${i}`} />
          })}

          {news && (
            <SubsiteNewsSectionApi
              perPage={8}
              hasBackground={false}
              slug={slug}
              host={host}
              sitename={sitename}
            />
          )}
          {facilities && <AllFacilities items={facilities} />}
          {activities && <AllFacilities items={activities} />}
        </div>
        <div className="side">
          {link && (
            <div className="button">
              <ButtonElement
                variant="primaryLink"
                href={link.href || link.external_url}
                isExternal={link.external_url}
              >
                {link.text}
              </ButtonElement>
            </div>
          )}

          {lists &&
            lists?.map((list, i) => {
              return (
                <InfoLinkList {...list?.value} key={`list-item-${i + 1}`} />
              )
            })}
        </div>
        {showOpeningtimes ? (
          <Openingtimes
            isActivities={isActivities}
            pageID={pageID}
            host={host}
            categories={categories}
            activityID={activityID}
          />
        ) : null}
      </Wrapper>
    </WidthContainer>
  )
}

export default ArticleIntro
