import React from 'react'
import styled from 'styled-components'

import InformationTabs from '../../modules/informationTabs'
import TypographyElement from '../../elements/typography'
import CardMedia from '../../compositions/informationImageCard'
import InfoCard from '../../compositions/informationCard'
import Width from '../../elements/widthContainer'

const InformationWrapper = styled.div`
  .introText {
    padding-bottom: 10px;
  }

  .tabContainer {
    padding-bottom: 20px;
  }

  .informationCards {
    padding: 20px 0;
  }

  .informationButtons {
    text-align: right;

    @media screen and (max-width: 650px) {
      button svg {
        display: none;
      }
    }
  }

  .cardwrapper {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    padding-left: 20px;

    &.large {
      width: 50%;
      padding-left: 0;
    }

    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  .contentModule {
    padding-bottom: 60px;
  }

  .infoCardWrapper {
    display: inline-block;
    vertical-align: top;
    padding: 20px;
    width: 25%;
  }
`

const NewsModuleCards = ({ title, button, data }) => {
  return (
    <div className="contentModule">
      <TypographyElement variant="ctaText">
        Voor {title} in Groningen
      </TypographyElement>

      <div className="informationCards">
        {data?.map((item, index) => {
          if (index > 2) {
            return
          }

          return (
            <div
              key={`info-card-ex-${index}`}
              className={`cardwrapper ${index === 0 ? 'large' : ''}`}
            >
              <CardMedia {...item} isLarge={index === 0} maxWidth />
            </div>
          )
        })}
      </div>

      <div className="informationButtons">
        <TypographyElement variant="footerText">
          <a href={button.href}>Meer informatie voor {title}</a>
        </TypographyElement>
      </div>
    </div>
  )
}

const Information = ({ tabs, intro, content }) => {
  const [active, setActive] = React.useState(tabs.activeId)

  const activeData = content.filter((item) => item.id === active)[0]

  return (
    <InformationWrapper>
      <Width>
        <div className="introText">
          <TypographyElement variant="title" component="h2">
            {intro.title}
          </TypographyElement>

          <TypographyElement variant="p">{intro.text}</TypographyElement>
        </div>

        <div className="tabContainer">
          <InformationTabs
            {...tabs}
            activeId={active}
            handleClick={(val) => setActive(val.id)}
          />
        </div>

        <div className="mainContent">
          {activeData.type === 'mediaCards' && (
            <div className="sectionWrap">
              {activeData.data?.map((card, index) => (
                <NewsModuleCards {...card} key={`${card.title}-${index}`} />
              ))}
            </div>
          )}

          {activeData.type === 'infoCards' && (
            <div className="sectionWrap">
              {activeData.data?.map((card, index) => (
                <div key={`${card.title}-${index}`} className="infoCardWrapper">
                  <InfoCard {...card} preClosed={index > 11} maxWidth />
                </div>
              ))}
            </div>
          )}
        </div>
      </Width>
    </InformationWrapper>
  )
}
export default Information
