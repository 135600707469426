'use client'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { subsite_background } from '../../colors'
import ButtonElement from '../../elements/buttons'
import WidthContainer from '../../elements/widthContainer'
import OpeningTimesModule from '../../modules/_subsite/openingtimes'
import { subsiteOpeningTimesApiClient } from '../../utils/api/fetchApi'
import { getMomentTz } from '../../utils/getMomentTz'
import TypographyElement from '../../elements/typography'

const Wrapper = styled.div`
  ${(props) =>
    props.multi &&
    `
        padding: 59px 113px 18px;

        @media screen and (max-width: 850px) {
            padding: 30px 60px 18px;
        }

        @media screen and (max-width: 550px) {
            padding: 30px 30px 18px;
        }

        @media screen and (max-width: 450px) {
            padding: 20px 20px 18px;
        }
        background-color: ${subsite_background}
    `};
  .contentBlock {
    margin-bottom: 80px;
    &.section,
    &.text {
      margin-bottom: 33px;
    }
  }
`

const TabWrapper = styled.div`
  display: inline-block;
  vertical-align: bottom;
  padding: 9px 33px;
  background-color: ${(props) =>
    props.selected ? subsite_background : '#C8E3EC'};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 200%;
`

const TabButton = ({ title, index, selected, setSelected }) => {
  const handleClick = () => {
    setSelected(index)
  }

  return (
    <ButtonElement
      variant="blank"
      onClick={handleClick}
      sx={{
        padding: 0,
        margin: 0,
        border: 0,
        borderRadius: 0,
      }}
    >
      <TabWrapper selected={selected == index}>{title}</TabWrapper>
    </ButtonElement>
  )
}

const Openingtimes = ({
  pageID,
  host,
  categories,
  activityID,
  isActivities = true,
}) => {
  const [selected, setSelected] = React.useState(1)
  const [openingtimes, setOpeningtimes] = React.useState([])

  const getOpeningtimes = async (date, category = null) => {
    let stringDate = moment(date).format('DD-MM-YYYY')
    const query = {
      start_datum: stringDate,
      page: pageID,
      days: 7,
      category: category,
    }
    if (activityID) {
      query.activity = activityID
    }
    const times = await subsiteOpeningTimesApiClient(host, query)
    setOpeningtimes({
      results: [
        {
          title: 'Openingstijden',
          content: times,
        },
      ],
    })
  }

  React.useEffect(() => {
    const start = getMomentTz().startOf('week')
    getOpeningtimes(start)
  }, [])

  const infoItems = openingtimes?.results
  const single = infoItems?.length <= 1

  if (!infoItems?.length) {
    return null
  }

  return (
    <>
      <TypographyElement variant="title">
        {isActivities ? "Activiteitstijden" : "Openingstijden"}
      </TypographyElement>
      <WidthContainer variant="semi">
        {!single && infoItems?.length
          ? infoItems?.map((item, i) => {
              return (
                <TabButton
                  title={item.title}
                  index={i + 1}
                  key={`tab-${i}-${item.title}`}
                  selected={selected}
                  setSelected={setSelected}
                />
              )
            })
          : null}
        <Wrapper multi={!single}>
          {infoItems?.length
            ? infoItems?.map((item, i) => {
                if (!selected) return null
                return (
                  <div
                    key={`mainpageinfo-${i}`}
                    className={`contentBlock ${item.type}`}
                  >
                    <OpeningTimesModule
                      openingtimes={item.content?.events}
                      {...item.content}
                      whiteBackground={!single}
                      getOpeningtimes={getOpeningtimes}
                      categories={categories}
                      isActivities={isActivities}
                    />
                  </div>
                )
              })
            : null}
        </Wrapper>
      </WidthContainer>
    </>
  )
}

export default Openingtimes
