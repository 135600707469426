import React from 'react'
import styled from 'styled-components'
import { grey_background } from '../../colors'

import TypographyElement from '../../elements/typography'
import { InfoItem } from '../../elements/buttons'

const Wrapper = styled.div`
  padding: 84px 198px;
  background: ${grey_background};

  @media screen and (max-width: 1680px) {
    padding: 84px 100px;
  }

  @media screen and (max-width: 1380px) {
    padding: 84px 50px;
  }

  @media screen and (max-width: 1000px) {
    padding: 64px 20px;
  }

  @media screen and (max-width: 600px) {
    padding: 24px 20px;
  }

  .items {
    padding-top: 22px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 0 5%;
    flex-wrap: wrap;
    > * {
      margin-bottom: 32px;

      div {
        word-wrap: break-word;
        max-width: 190px;
      }
    }
  }
`

const InfoList = ({ title, items, withArrow }) => {
  return (
    <Wrapper>
      <TypographyElement component="h2" variant="title">
        {title}
      </TypographyElement>
      <div className="items">
        {items?.map((item, i) => {
          return (
            <InfoItem
              {...item}
              key={`${item.text}-${i}`}
              withArrow={withArrow}
            />
          )
        })}
      </div>
    </Wrapper>
  )
}

export default InfoList
