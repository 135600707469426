'use client'
import React from 'react'
import styled from 'styled-components'
import WidthContainer from '../../elements/widthContainer'
import TitleTextLinkBlock from '../../modules/_subsite/titleTextLinkBlock'

const Wrapper = styled.div`
  margin: 120px 0;
`

const AboutSection = ({ title, text, link }) => {
  return (
    <WidthContainer variant="small">
      <Wrapper>
        <TitleTextLinkBlock title={title} text={text} link={link} />
      </Wrapper>
    </WidthContainer>
  )
}

export default AboutSection
