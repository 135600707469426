import React from 'react'
import styled from 'styled-components'

import Field from '../../elements/fields'

const Ordering = ({ order, handleOrderChange = () => {}, orderOptions }) => {
  return (
    <Field
      variant="select"
      label=""
      required={false}
      handleChange={handleOrderChange}
      id="order"
      value={order}
      options={orderOptions}
      width="smallest"
      issearch="true"
    />
  )
}

export default Ordering
