import React from 'react'
import styled from 'styled-components'

import ButtonElement from '../../elements/buttons'
import WidthContainer from '../../elements/widthContainer'

import { SectionElement } from '../../compositions/articleSection'
import InfoLinkList from '../../compositions/infoLinksList'
import DateBlock from '../../compositions/redDateBlock'

import SignUpForm from '../../modules/signupForm'
import TypographyElement from '../../elements/typography'
import SvgElement from '../../elements/svg'
import ClubInfo from '../../compositions/clubInfo'
import { getMomentTz } from '../../utils/getMomentTz'
import TableSection from '../table'
import IframeSection from '../iframeSection'
import FormBuilderSection from '../FormBuilderSection'

const Wrapper = styled.div`
  display: block;
  position: relative;
  .dateblock {
    position: absolute;
    left: -140px;
    top: 0;

    @media screen and (max-width: 1568px) {
      left: 0;
      position: relative;
      margin-right: 30px;
      display: inline-block;
    }

    @media screen and (max-width: 700px) {
      margin: 0;
    }
  }
  .intro {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 360px - 30px);
    padding-right: 80px;
    h3 {
      padding: 0;
    }

    @media screen and (max-width: 1568px) {
      padding: 0 80px 0 0;

      width: calc(100% - 125px - 360px);

      &.nodate {
        width: calc(100% - 360px);
      }
    }

    @media screen and (max-width: 1050px) {
      padding: 0 0px;

      width: calc(100% - 125px);

      &.nodate {
        width: 100%;
      }
    }

    @media screen and (max-width: 700px) {
      width: 100%;
    }

    @media screen and (max-width: 550px) {
      padding-left: 0px;
      padding-top: 20px;
      width: calc(100%);
      padding-right: 0px;
    }

    .content {
      margin-top: 24px;

      @media screen and (max-width: 700px) {
        margin-top: 60px;
      }
      @media screen and (max-width: 550px) {
        margin-top: 30px;
      }

      .tag {
        display: inline-block;
        margin-right: 12px;
        margin-bottom: 4px;
        svg {
          margin-right: 6px;
        }
        > * {
          display: inline-block;
          vertical-align: middle;
        }
      }

      &.no_topmargin {
        @media screen and (max-width: 550px) {
          margin-top: 0px;
        }
      }
    }
  }
  .side {
    display: inline-block;
    vertical-align: top;
    width: 360px;

    @media screen and (max-width: 1050px) {
      width: 100%;
      padding-top: 60px;
    }

    .button {
      margin-bottom: 46px;

      > span.linkWrapper {
        display: block;
        text-align: center;
      }
      a {
        display: block;
        text-align: center;
        span {
          display: inline-block;
        }

        svg {
          position: relative;
          top: 3px;
        }
      }

      @media screen and (max-width: 600px) {
        svg {
          display: none;
        }
      }
    }
    > div {
      margin-bottom: 24px;
    }
  }
`

const elementDict = {
  section: SectionElement,
  prices: ClubInfo,
  form: FormBuilderSection,
  table: TableSection,
  embed: IframeSection,
}

const Article = ({
  title,
  startdate,
  enddate,
  tags,
  article,
  link,
  lists,
  locationsAddresses,
  clubLinks,
  inPast,
  host,
}) => {
  const startDate = getMomentTz(startdate)
  const endDate = enddate && getMomentTz(enddate)
  const start = `${startDate.format('dddd DD MMMM')} om ${startDate.format(
    'HH:mm'
  )}u`
  const end = endDate ? ` t/m ${endDate.format('dddd DD MMMM')}` : ''

  return (
    <WidthContainer variant="small">
      <Wrapper>
        {startdate && (
          <div className="dateblock">
            <DateBlock
              day={startDate.format('DD')}
              month={startDate.format('MMM')}
            />
          </div>
        )}
        <div className={`intro ${!startdate ? 'nodate' : ''}`}>
          {title && (
            <TypographyElement variant="title" component="h2">
              {title}
            </TypographyElement>
          )}
          {startdate && (
            <TypographyElement variant="eventBlockSubtitle">
              {start}
              {end}
            </TypographyElement>
          )}
          <div
            className={`content ${!title && !startdate ? 'no_topmargin' : ''}`}
          >
            {tags &&
              tags?.map((tag) => {
                return (
                  <div key={tag.name} className="tag">
                    <SvgElement variant="greenDot" />
                    <TypographyElement variant="greenTag">
                      {tag.name}
                    </TypographyElement>
                  </div>
                )
              })}
            {article?.map((item, index) => {
              const Element = elementDict[item.type]
              return (
                <Element
                  host={host}
                  pagetitle={title}
                  key={`ele-key-${index}`}
                  {...item.value}
                  inPast={inPast}
                  isFirst={false}
                />
              )
            })}
          </div>
        </div>
        <div className="side">
          {link ? (
            <div className="button">
              <ButtonElement
                variant="primaryLink"
                href={link.href || link.external_url}
              >
                {link.text}
              </ButtonElement>
            </div>
          ) : null}

          {lists
            ? lists?.map((list) => {
                return <InfoLinkList {...list.value} key={list.id} />
              })
            : null}
          {(locationsAddresses?.links || locationsAddresses?.items) && (
            <InfoLinkList {...locationsAddresses} key={`location-addresses`} />
          )}
          {(clubLinks?.links || clubLinks?.items) && (
            <InfoLinkList {...clubLinks} key={`location-addresses`} />
          )}
        </div>
      </Wrapper>
    </WidthContainer>
  )
}

export default Article
