import React from 'react'
import styled from 'styled-components'

// Elements
import TypographyElement from '../../elements/typography'
import ImageBuilder from '../../elements/imageBuilder'
// compositions
import LinkList from '../../compositions/linkListBlock'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin: 50px 0;

  .contentContainer {
    flex: 0.3;
    order: ${(props) => (props.variant == 'right' ? 2 : 1)};
  }
  .imageContainer {
    flex: 0.6;
    order: ${(props) => (props.variant == 'right' ? 1 : 2)};
    span {
      width: 100% !important;
      img {
        width: inherit !important;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    display: block;

    .contentContainer,
    .imageContainer {
      width: 100%;
      flex: 1;
    }
  }
`

const LinkListImageBlock = ({ title, links, image, variant }) => {
  return (
    <Wrapper variant={variant}>
      <div className="contentContainer">
        <TypographyElement variant="moreTitle" component="h2">
          {title}
        </TypographyElement>
        <LinkList links={links} />
      </div>
      {image && (
        <div className="imageContainer">
          <ImageBuilder
            decorativeOnly={true}
            image={image}
            height={372}
            width={644}
          />
        </div>
      )}
    </Wrapper>
  )
}

export default LinkListImageBlock
