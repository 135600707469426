'use client'
import React from 'react'
import styled from 'styled-components'
import dynamic from 'next/dynamic'

import WidthContainer from '../../elements/widthContainer'

import SubsiteNewsSection from '../newsSection'
import ArticleImages from '../../modules/_subsite/articleImages'
import AllFacilities from '../allFacilities'
import FacilityPrices from '../../modules/_subsite/facilityPrices'
import ArticleRichText from '../articleRichText'
import SubsiteShortNewsSection from '../shortNewsSection'
import ContactBlock from '../../modules/_subsite/contactBlock'
import MainInfo from '../mainPageInfo'
import { SectionElement } from '../../compositions/articleSection'
import Tables from '../tabels'
import Rates from '../rates'
import FormBuilderSection from '../../sections/FormBuilderSection'

const ArticleWrapper = styled.div`
  ${(props) =>
    props.background
      ? `
        background-color: ${props.background}
    `
      : ``}
`

// Dynamically import since window is required
const DynamicShare = dynamic(() => import('../../modules/_subsite/share'), {
  ssr: false,
})

const Wrapper = styled.div`
  display: block;
  padding-top: 50px;

  .intro {
    display: inline-block;
    vertical-align: top;
    margin-right: 30px;
    width: 100%;
    overflow: hidden;

    .imagesContainer {
      span {
        overflow: visible !important;
        img {
          overflow: visible;
          object-fit: cover;
        }
      }
    }
  }
  .side {
    display: inline-block;
    vertical-align: top;
    width: 310px;
    .button {
      margin-bottom: 46px;

      @media screen and (max-width: 600px) {
        svg {
          display: none;
        }
      }
    }
    > div {
      margin-bottom: 24px;
    }
  }
  @media screen and (max-width: 1000px) {
    padding-top: 0px;
  }
`

const mapElements = {
  images: ArticleImages,
  facilities: AllFacilities,
  openingtimes: MainInfo,
  news: SubsiteNewsSection,
  prices: FacilityPrices,
  text: ArticleRichText,
  facilityprices: FacilityPrices,
  tables: Tables,
  shortnews: SubsiteShortNewsSection,
  contact: ContactBlock,
  share: DynamicShare,
  section: SectionElement,
  rates: Rates,
  form: FormBuilderSection,
}

const Article = ({ content, background, pageTitle, description }) => {
  return (
    <ArticleWrapper background={background}>
      <WidthContainer variant="semi" background={background}>
        <Wrapper>
          <div className="intro">
            {content?.map((item, i) => {
              const Element =
                item.type in mapElements ? mapElements[item.type] : null
              if (!Element) return null
              return (
                <Element
                  pageTitle={pageTitle}
                  description={description}
                  {...item.value}
                  key={`element-${i}`}
                  narrow={item.type == 'section'}
                />
              )
            })}
          </div>
        </Wrapper>
      </WidthContainer>
    </ArticleWrapper>
  )
}

export default Article
