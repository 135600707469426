'use client'
import React from 'react'
import styled from 'styled-components'

import WidthContainer from '../../elements/widthContainer'
import FacilitiesBlock from '../../modules/_subsite/facilities'
import ImageBuilder from '../../elements/imageBuilder'

const Wrapper = styled.div`
  margin-bottom: 160px;
  .imageContainer {
    width: 100%;
    aspect-ratio: 2.86;
    display: ${(props) => (props.background ? 'block' : 'none')};
    max-height: 890px;
    overflow: hidden;
    position: relative;

    span {
      height: inherit !important;
      img {
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .contentContainer {
    display: block;
    margin-top: -207px;
    position: relative;
    z-index: 99;
  }
`

const FacilitiesSection = ({ title, facilities, link, image }) => {
  return (
    <Wrapper background={image?.url || image}>
      <div className="imageContainer">
        <ImageBuilder
          decorativeOnly={true}
          alt={title}
          image={image?.url || image}
          layout="fill"
        />
      </div>
      <div className="contentContainer">
        <WidthContainer variant="semi">
          <FacilitiesBlock title={title} items={facilities} link={link} />
        </WidthContainer>
      </div>
    </Wrapper>
  )
}

export default FacilitiesSection
