'use client'
import React from 'react'
import styled from 'styled-components'

import SubsiteHeader from '../../modules/_subsite/header'

const HeaderWrapper = styled.div``

const SubsiteHeaderSection = ({ header }) => {
  return (
    <HeaderWrapper>
      <SubsiteHeader {...header} />
    </HeaderWrapper>
  )
}
export default SubsiteHeaderSection
