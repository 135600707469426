'use client'
import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material'

import SubsiteNewsCard from '../../compositions/_subsite/newsCard'
import WidthContainer from '../../elements/widthContainer'

import PaginationElement from '../../elements/pagination'

import { subsiteNewsApiClient } from '../../utils/api/fetchApi'

const Container = styled.div`
  background: ${(props) => props.background};
  margin-bottom: 100px;
  .news {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .button {
    text-align: right;
    > span.linkWrapper {
      width: 100%;
    }
    a {
      text-align: center;
    }

    @media screen and (max-width: 600px) {
      svg {
        display: none;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const SubsiteNewsSectionApi = ({
  hasBackground = true,
  perPage = 8,
  slug = null,
  host = '',
  sitename = '',
}) => {
  const theme = useTheme()
  const [page, setPage] = React.useState(1)
  const [news, setNews] = React.useState([])
  const [pages, setPages] = React.useState(1)

  const getNews = async ({ pageInput }) => {
    const query = {
      slug,
      page,
      per_page: perPage,
      sitename,
    }
    const data = await subsiteNewsApiClient(host, query)
    setNews(data?.search_results?.pages || [])
    setPages(data?.search_results?.num_pages)
  }

  React.useEffect(() => {
    getNews({ pageInput: page })
  }, [])

  const handlePaginationChange = (e, value) => {
    getNews({ pageInput: value })
    window?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    setPage(value)
  }

  return (
    <WidthContainer variant="full">
      <Container
        background={
          hasBackground
            ? theme?.globalTheme?.palette?.backgroundColor?.main
            : 'transparent'
        }
      >
        <div className="news">
          {news?.map((item, index) => {
            return (
              <SubsiteNewsCard
                key={`subsite-news-card-${index}`}
                {...item}
                date={item?.date || item?.last_published_at}
                isLarge={false}
              />
            )
          })}
        </div>

        {pages > 1 && (
          <PaginationElement
            page={page}
            count={pages}
            handleChange={handlePaginationChange}
          />
        )}
      </Container>
    </WidthContainer>
  )
}

export default SubsiteNewsSectionApi
